.tooltip {
    &-trigger {
        font-style: normal !important;
        display: inline-block !important;
        vertical-align: top !important;
        position: relative;
        width: 16px;
        height: 16px;
        font-size: 10px;
        text-align: center;
        line-height: 15px;
        border-radius: 50%;
        border: 1px solid $color-gray;
        background-color: $color-light;
        color: $color-dark;
        @include anim-f(color, border);
        margin-left: 0.5em;

        &:before {
            content: "?";
            opacity: 0.7;
        }

        &:hover,
        &:focus {
            border-color: $color-accent;
            color: $color-accent;

            .tooltip-content {
                opacity: 1;
                visibility: visible;
                transition-delay: 0s;
            }
        }
    }

    &-content {
        width: 200px;
        height: auto;
        position: absolute;
        display: block;
        top: 100%;
        right: 50%;
        margin-right: -100px;
        font-size: 12px;
        line-height: 1.5em;
        padding: 10px;
        background-color: $color-light;
        border-radius: 5px;
        box-shadow: 4px 4px 20px rgba($color-raw-dark, 0.2);
        color: $color-dark;
        font-style: normal;
        font-weight: normal;
        text-align: left;
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.001s linear 0.5s, opacity 0.3s ease 0.2s;
        margin-top: 10px;

        @include themeDark{
            box-shadow: 4px 4px 20px rgba($color-raw-reverse-light, 0.2);
        }

        &:before{
            @include pseudos;
            @include triangle(up, 20px, 8px, $color-light);
            left: 50%;
            margin-left: -10px;
            bottom: 100%;
            position: absolute;
        }
    }
}
