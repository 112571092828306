/**
В данный файл нужно писать стили для заголовков, списков, параграфов и т.д.
*/

%header-large {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 125%;
    letter-spacing: 0.01em;
    font-family: $font2;

    @include bp(lg) {
        font-weight: 500;
        font-size: 54px;
    }
}

%header-block{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: 0.01em;
    font-family: $font2;

    @include bp(lg){
        font-weight: bold;
        font-size: 36px;
    }
}

%header-medium{
    font-family: $font2;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: 0.01em;
}

%header-small{
    font-family: $font2;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.25em;
    letter-spacing: 0.01em;
}

%list-numerated {
    counter-reset: lst;

    & > li {
        padding-left: em(24, 18);
        position: relative;
        counter-increment: lst;
        margin-bottom: em(10, 18);

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            content: counter(lst) ".";
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            font-size: inherit;
            line-height: inherit;
        }
    }
}

%list-default {
    & > li {
        margin-bottom: em(10, 18);
        position: relative;
        padding-left: 1em;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            @include pseudos;
            position: absolute;
            width: 8px;
            height: 8px;
            left: 0;
            top: em(9, 18);
            background-color: $color-accent;
            border-radius: 50%;
        }
    }
}

%collapse-margins {
    & > *:not(.row) {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
