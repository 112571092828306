.page-wrap.page_inner {
    @include bp(lg) {
        overflow: visible !important;

        .block_default {
            overflow: hidden;
            width: 100%;
        }
    }

    @include bp(xl) {
        .page_header,
        .page_footer {
            width: 100%;
            overflow: hidden;
        }
    }
}
