.accordion {
    &-root {
    }

    &-header {
        cursor: pointer;
        display: block;
    }

    &-content {
        overflow: hidden;
        transition: max-height 0.2s ease-in;
        @include anim-m(max-height);

        .accordion-root:not(.expanded) > & {
            max-height: 0 !important;
        }
    }

    &-container {
    }
}

.accordion-extra {
    &-head {
        @include bp(lg) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
        }
    }

    .accordion-container {
        padding-top: 20px;

        @include bp(lg) {
            padding-top: 40px;
        }

        .input_container {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .accordion-header {
        display: inline-block;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 125%;
        letter-spacing: 0.01em;
        color: $color-accent;
        @include anim-f(color);

        &:hover {
            color: $color-dark;
        }

        & > span {
            text-decoration: underline;

            &:first-child {
                display: inline-block;
            }

            &:last-child {
                display: none;
            }
        }
    }

    &.expanded {
        .accordion-header {
            & > span {
                &:first-child {
                    display: none;
                }

                &:last-child {
                    display: inline-block;
                }
            }
        }
    }

    h3 {
        @extend %header-medium;
        margin-bottom: 15px;

        @include bp(md) {
            margin-bottom: 0;
        }
    }
}

.accordion_faq {
    margin-top: 20px;
    padding-bottom: 20px;
    position: relative;

    &:after {
        @include pseudos;
        width: 100%;
        height: 1px;
        background-color: $color-accent;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .accordion-header {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 125%;
        letter-spacing: 0.01em;
        padding-right: 50px;
        position: relative;
        @include anim-f(color);

        &:hover {
            color: $color-accent;
        }

        &:after {
            @include pseudos;
            @include arrow(12px, 2px, $color-accent, down);
            right: 3px;
            top: 6px;
            position: absolute;
        }
    }

    &.expanded {
        .accordion-header {
            color: $color-accent;

            &:after {
                transform: rotate(-225deg);
                top: 13px;
            }
        }
    }

    .accordion-container {
        padding-top: 15px;

        .block_text {
            color: rgba($color-raw-dark, 0.7);

            @include themeDark {
                color: rgba($color-raw-reverse-dark, 0.7);
            }
        }
    }
}
