.block_list{
    .block_default--header{
        text-align: left;

        .block_text{
            margin: 0;
            max-width: none;
        }
    }

    .block_default--foot{
        margin-top: 40px;
    }

    &--list{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;
        margin-bottom: -60px;

        @include bp(md){
            margin-bottom: -40px;
        }

        .block_news--slider-item{
            width: 100%;
            margin-right: 0;
            margin-bottom: 60px;

            @include bp(md, lg){
                width: calc(50%  - 10px);

                &:nth-child(2n){
                    margin-right: 0;
                }
            }

            @include bp(md){
                margin-right: 20px;
                margin-bottom: 40px;
            }

            @include bp(lg){
                width: calc(33.333% - 13.333px);
                @include anim-s(transform);

                &:hover{
                    transform: translateY(-10px);
                }

                &:nth-child(3n){
                    margin-right: 0;
                }
            }
        }
    }
}