.page_payment{
    &--layout{
        .block_default--header {
            text-align: left;

            h1 {
                @extend %header-large;
            }
        }

        .block_default--content{
            @include bp(lg){
                width: calc(100% + 40px);
            }
        }

        .wrapper{
            z-index: 1;
        }

        &-bg{
            position: absolute;
            z-index: -1;
            width: 100vw;
            left: 50%;
            margin-left: -50vw;
            height: 305px;
            background-repeat: repeat-x;
            background-position: 100% 100%;
            background-size: auto 100%;
            bottom: -60px;

            @include bp(md){
                bottom: -40px;
                background-position: 50% 100%;
            }
        }

        &-item{
            @extend %bg-form;
            border-radius: 10px;
            margin-bottom: 40px;
            width: 100%;
            padding: 40px 20px;
            position: relative;

            @include bp(md){
                padding: 40px;
            }

            @include bp(lg){
                width: calc(50% - 40px);
                margin-right: 40px;
            }

            &.alt{
                background: none;
                @extend %border-gradient;

                &:before{
                    border-width: 2px;
                }
            }

            &.fullscreen{
                @include bp(lg){
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 60px 40px;

                    .page_payment--layout-item-link{
                        margin-top: 0;
                        margin-left: 100px;
                        flex-grow: 0;
                        flex-shrink: 0;
                        max-width: 270px;
                    }

                    .block_text{
                        max-width: 650px;
                    }
                }
            }

            &-link{
                display: inline-block;
                margin-top: 20px;
                position: relative;
                padding-right: 20px;
                @include anim-f(opacity);
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 125%;
                letter-spacing: 0.01em;
                color: $color-accent;

                &:hover{
                    opacity: 0.7;
                }

                &:after{
                    @include pseudos;
                    background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.5H11M11 5.5L6.5 1M11 5.5L6.5 10' stroke='%23FF7300' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    width: 12px;
                    height: 12px;
                    top: 50%;
                    right: 0;
                    margin-top: -5px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                }
            }
        }

        .block_default--content{
            margin-bottom: -40px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
        }
    }
}