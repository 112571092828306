.developer{
    font-size: 29px;
    width: (118/29)*1em;
}
.icon-computer{
    font-size: 50px;
    width: (50/50)*1em;
}
.icon-console{
    font-size: 30px;
    width: (30/30)*1em;
}
.icon-devices{
    font-size: 30px;
    width: (30/30)*1em;
}
.icon-flat{
    font-size: 21px;
    width: (20/21)*1em;
}
.icon-house{
    font-size: 21px;
    width: (20/21)*1em;
}
.icon-money{
    font-size: 30px;
    width: (30/30)*1em;
}
.icon-plus{
    font-size: 21px;
    width: (20/21)*1em;
}
.icon-router{
    font-size: 30px;
    width: (30/30)*1em;
}
.icon-spot{
    font-size: 30px;
    width: (31/30)*1em;
}
.icon-tv{
    font-size: 30px;
    width: (31/30)*1em;
}
.icon-user{
    font-size: 21px;
    width: (20/21)*1em;
}
.icon-wifi{
    font-size: 30px;
    width: (31/30)*1em;
}
.logo{
    font-size: 45px;
    width: (173/45)*1em;
}
