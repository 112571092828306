.swiper {
    &-root {
        position: relative;
        display: block;
    }

    &-button {
        &-prev,
        &-next {
            width: 15px;
            height: 30px;
            font-size: 0;
            padding: 0;
            position: absolute;
            z-index: 2;
            top: auto;
            bottom: 0;
            margin: 0;
            right: 0;
            left: auto;
            transform: none;
            background-color: transparent;
            border: none;
            @include anim-f(opacity);

            &:before {
                @include pseudos;
                @include arrow(24px, 2px, $color-dark, right);
                top: 50%;
                right: 5px;
                margin-top: -12px;
                position: absolute;
                @include anim-f(border-color);
            }

            &:hover {
                &:before {
                    border-color: $color-accent;
                }
            }

            &:disabled,
            &.swiper-button-disabled {
                pointer-events: none;
                opacity: 0.5;
            }

            &:after {
                display: none;
            }
        }

        &-prev {
            transform: scaleX(-1);
            left: 0;
            right: auto;
        }
    }

    &-pagination {
        // position: absolute;
        // width: 100%;
        // top: auto;
        // bottom: rem(40px);
        // left: 0;
        z-index: 3;

        // @include not-small {
        //     bottom: rem(42px);
        // }

        // &-bullets {
        // height: 0;
        // font-size: 0;
        // text-align: center;
        // display: flex;
        // flex-direction: row;
        // justify-content: center;
        // align-items: flex-end;
        // }

        // &-bullet {
        // display: inline-block;
        // vertical-align: bottom;
        // margin: 0 rem(30px) 0 0;
        // width: rem(12px);
        // height: rem(12px);
        // padding: 0;
        // border-radius: 50%;
        // border: rem(2px) solid gold;
        // @include anim-f(background, border);
        // background: none;
        // background-color: rgba(255, 255, 255, 0);
        // opacity: 1;
        // cursor: pointer;
        // pointer-events: all;
        // border-radius: 50% !important;
        // outline: none;

        // // @include for-medium {
        // //     margin-right: rem(50px);
        // // }

        // // @include for-desks {
        // //     margin-right: rem(70px);

        // //     &:hover {
        // //         border-color: white;
        // //     }
        // // }

        // &:last-child {
        //     margin-right: 0;
        // }

        // &-active {
        //     border-color: white;
        //     background-color: white;
        // }
        // }

        &-fraction {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 125%;
            text-align: center;
            letter-spacing: 0.01em;
            position: absolute;
            width: 100%;
            height: 0;
            color: $color-accent;

            .swiper-pagination-total {
                color: rgba($color-raw-dark, 0.7);
                font-weight: 400;

                @include themeDark {
                    color: rgba($color-raw-reverse-dark, 0.7);
                }
            }
        }
    }

    &-lock {
        display: none !important;
    }

    &-lazy {
        opacity: 0;
        @include anim-f(opacity);

        &-loaded {
            opacity: 1;
        }

        &-preloader {
            position: absolute;
            display: block;
            top: 50% !important;
            left: 50% !important;
            margin-top: -15px !important;
            margin-left: -15px !important;
            width: 30px !important;
            height: 30px !important;
            transform-origin: 50% 50%;
            transform: rotate(0deg);
            border-style: solid;
            border-width: 0 2px 2px;
            border-color: $color-dark $color-dark $color-dark transparent;
            border-radius: 50%;

            @keyframes spinSwiperPreloader {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            animation: spinSwiperPreloader 1.5s linear infinite;
        }
    }

    &-container {
        &-autoheight {
            & > .swiper-wrapper {
                transition: height 0.3s ease !important;
            }
        }
    }
}

%swiper-track-visible {
    &.swiper-container,
    .swiper-container {
        overflow: visible !important;
    }
}

%navigation-alt{
    @include bp(lg){
        padding-top: 70px;
    }

    .swiper-pagination-fraction{
        position: absolute;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 125%;
        letter-spacing: 0.01em;
        font-family: $font2;
        right: 0;
        left: auto;
        width: 160px;
        text-align: center;
        z-index: 1;
        top: 15px;

        .swiper-pagination-total {
            color: rgba($color-raw-dark, 0.5);
            font-weight: 500;

            @include themeDark {
                color: rgba($color-raw-reverse-dark, 0.5);
            }
        }
    }

    .swiper-button-prev,.swiper-button-next{
        z-index: 2;
        left: auto;
        right: 0;
        top: 0;
        bottom: auto;
    }

    .swiper-button-prev{
        margin-right: 145px;
    }
}

@mixin reset-slider() {
    //width: 100%;
    & > .swiper-container {
        overflow: visible;
        height: auto;
        width: auto;

        & > .swiper-wrapper {
            height: auto !important;
            transform: none !important;
            width: 100% !important;
            display: block;

            & > .swiper-slide {
                width: auto;
                transform: none !important;
            }
        }
    }

    .swiper {
        &-button-prev,
        &-button-next,
        &-navigation,
        &-pagination {
            display: none;
        }
    }
}
