.form_default {
    input[type="checkbox"],
    input[type="radio"] {
        @include hideField;

        & ~ .input_container--error {
            margin-left: 30px;
        }

        & ~ .input_container--label {
            transform: none;
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            display: inline-block;
            font-family: $font1;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 125%;
            letter-spacing: 0.01em;
            pointer-events: all;
            opacity: 1;
            top: 0;
            left: 0;
            white-space: normal;
            text-overflow: none;
            width: auto;
            max-width: none;
            right: auto;
            overflow: visible;

            &.reversed{
                padding-left: 0;
                padding-right: 30px;

                &:before{
                    left: auto;
                    right: 2px;
                }
            }

            &.check_big {
                padding-left: 35px;

                &:before{
                    width: 24px;
                    height: 24px;
                    left: 3px;
                    top: -1px;
                }

                &.reversed{
                    padding-left: 0;
                    padding-right: 35px;

                    &:before{
                        right: 3px;
                        left: auto;
                    }
                }
            }

            a,
            button {
                text-decoration: underline;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                color: inherit;
                display: inline !important;
                width: auto;
                font-weight: 300;

                &:hover {
                    color: $color-accent;
                }
            }

            strong,
            b {
                font-weight: 700;
            }

            &:before {
                @include pseudos;
                position: absolute;
                left: 2px;
                top: 3.5px;
                width: 16px;
                height: 16px;
                border: 1px solid $color-accent;
                background-color: transparent;
                @include anim-f(border);
            }

            &:after {
                @include pseudos;
                position: absolute;
                opacity: 0;
                @include anim-f(background, opacity);
            }
        }

        &:focus ~ .input_container--label,
        & ~ .input_container--label:hover {
            &:before {
                border-color: $color-accent-hover;
            }
        }

        &:checked ~ .input_container--label {
            &:after {
                opacity: 1;
            }
        }
    }

    input[type="checkbox"] {
        & ~ .input_container--label {
            &:before {
                border-radius: 2px;
            }

            &.check_big{
                &:after{
                    width: 14px;
                    height: 8px;
                    border-width: 0 0 3px 3px;
                    left: 8px;
                    top: 5px;
                }

                &.reversed:after{
                    left: auto;
                    right: 8px;
                }
            }

            &.reversed:after{
                left: auto;
                right: 5px;
            }

            &:after {
                transform-origin: 50% 50%;
                transform: rotate(-45deg);
                width: 10px;
                height: 5px;
                border-style: solid;
                border-width: 0 0 2px 2px;
                border-color: $color-accent;
                left: 5px;
                top: 8px;
            }
        }
    }

    input[type="radio"] {
        & ~ .input_container--label {
            &:before,
            &:after {
                border-radius: 50%;
            }

            &:after {
                background-color: $color-accent;
                width: 10px;
                height: 10px;
                top: 5px;
                left: 5px;
            }

            &:before {
                border-width: 2px;
            }
        }

        &:focus ~ .input_container--label,
        & ~ .input_container--label:hover {
            &:before {
                border-color: $color-accent-hover;
            }
        }
    }
}
