$header-big: 180px;
$header-small: 90px;

.page_header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $z-header;

    @include bp-to(xl) {
        &.fix {
            &:not(.expanded) {
                transform: translateY(-100%) translateZ(0);
                opacity: 0;
            }

            &.show:not(.expanded) {
                transform: none;
                opacity: 1;
                transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, max-height 0.3s ease-in-out;
            }

            &.hide:not(.expanded) {
                transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, max-height 0.3s ease-in-out;
            }
        }

        &.expanded,
        &.fix {
            position: fixed;
        }
    }

    &--placeholder,
    & {
        height: $header-small;

        @include bp(xl) {
            height: $header-big;
        }
    }

    &--main {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
        background-color: $color-light;

        @include bp(xl){
            height: 0;
            background-color: transparent;
        }

        .wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @include bp(xl){
                display: block;

                &:before{
                    @include pseudos;
                    width: 100%;
                    height: 1px;
                    background-color: $color-gray;
                    position: absolute;
                    left: 0;
                    top: 82px;
                }
            }
        }

        &-logo {
            display: inline-block;
            font-size: 0;
            line-height: 0;

            @include bp(xl){
                position: absolute;
                left: 0;
                top: 104px;
            }

            svg {
                width: 125px;
                height: 33px;
                fill: $color-dark;

                @include bp(xl) {
                    width: 173px;
                    height: 45px;
                }
            }
        }

        &-burger {
            $burger-square: 40px;
            $burger-line-width: 26px;
            $burger-line-thickness: 1px;
            $burger-line-gap: 8px + $burger-line-thickness;
            $burger-color: $color-dark;

            width: $burger-square;
            height: $burger-square;
            position: relative;
            display: block;

            @include bp(xl) {
                display: none;
            }

            &:before,
            &:after {
                @include pseudos;
                width: $burger-line-width;
                height: $burger-line-thickness;
                background-color: $burger-color;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: #{-$burger-line-thickness/2} #{-$burger-line-width/2};
                transform-origin: 50% 50%;
                @include anim-f(background-color);
            }

            &:before {
                box-shadow: 0 $burger-line-gap 0 0 $burger-color, 0 #{-$burger-line-gap} 0 0 $burger-color;
            }

            .expanded & {
                &:before {
                    box-shadow: none;
                    transform: translateZ(0) rotate(-45deg);
                }

                &:after {
                    transform: translateZ(0) rotate(45deg);
                }
            }
        }
    }

    &--menu {
        height: vh(100);
        width: 100%;
        padding-top: $header-small;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-300px);
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.001s linear 0.4s, opacity 0.4s ease, transform 0.4s ease;
        z-index: 1;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @extend %bg-form;

        @include bp(xl) {
            display: block;
            padding-top: 0;
            transform: none;
            opacity: 1;
            transition: none;
            visibility: visible;
            z-index: 3;
            background: none;

            &,
            .wrapper,
            &_scroll {
                height: 0;
            }
        }

        .expanded & {
            visibility: visible;
            opacity: 1;
            transform: none;
            transition-delay: 0s;
        }

        &-scroll {
            max-height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;

            @include bp(xl) {
                overflow: visible;
                max-height: none;
            }

            .wrapper {
                flex-shrink: 0;
                padding: 40px 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                @include bp(xl) {
                    display: block;
                    padding: 0;
                }
            }
        }

        &-cabinet {
            width: 100%;
            margin-bottom: 15px;

            @include bp(xl){
                width: auto;
                position: absolute;
                right: 0;
                top: 40px;
            }

            a {
                display: inline-block;
                font-family: $font2;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 125%;
                letter-spacing: 0.01em;
                padding-left: 30px;
                position: relative;

                @include bp(xl){
                    font-size: 18px;
                    line-height: 125%;
                    letter-spacing: 0.01em;
                    font-family: $font1;
                    font-weight: 400;

                    svg{
                        top: 1px;
                    }
                }

                svg {
                    position: absolute;
                    left: 0;
                    top: 2px;
                    width: 20px;
                    height: 20px;
                    fill: $color-accent;
                }

                &:hover {
                    color: $color-accent;
                }
            }
        }

        &-nav {
            margin-bottom: 84px;
            width: 100%;

            @include bp(xl) {
                width: auto;
                flex-grow: 1;
                margin: 0;
            }

            & > div {
                display: flex;
                flex-direction: column;
                text-align: left;
                align-items: flex-start;
                width: 100%;

                @include bp(md){
                    flex-direction: row;
                    flex-wrap: wrap;

                    a{
                        margin-right: 30px;
                    }
                }

                @include bp(xl){
                    position: absolute;
                    width: auto;

                    &:nth-child(1){
                        top: 40px;
                        left: 0;
                    }

                    &:nth-child(2){
                        right: 230px;
                        top: 117px;
                    }
                }
            }

            a {
                display: inline-block;
                margin-top: 23px;
                @include anim-f(color);
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 125%;
                letter-spacing: 0.01em;

                @include bp(xl) {
                    margin-top: 0;
                    margin-right: 30px;
                    font-weight: 400;

                    &:last-child{
                        margin-right: 0;
                    }
                }

                &.active,
                &:hover {
                    color: $color-accent;
                }
            }
        }

        &-contacts {
            width: 100%;

            a[href^="tel"] {
                display: inline-block;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 125%;
                letter-spacing: 0.01em;
            }

            p {
                margin-bottom: 30px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: baseline;

                @include bp(md){
                    justify-content: flex-start;

                    a{
                        margin-right: 30px;

                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }

                @include bp(xl){
                    position: absolute;
                    width: auto;
                    right: 207px;
                    top: 40px;
                }
            }

            .btn {
                @include bp(md, xl) {
                    max-width: 320px;
                }

                @include bp(xl) {
                    position: absolute;
                    right: 0;
                    top: 103px;
                    width: auto;
                }
            }
        }
    }
}
