fieldset.form_default--slider {
    padding: 53px 0 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 40px;

    @include bp(lg) {
        padding-top: 49px;
        margin-bottom: 60px;
    }

    &:before {
        @include pseudos;
        width: 100%;
        background-color: $color-dark;
        opacity: 0.1;
        z-index: 1;
        top: 56px;
        height: 2px;
        position: absolute;
        left: 0;
    }

    legend {
        display: block;
        @extend %header-medium;
        position: absolute;
        left: 0;
        top: 0;
    }

    & > small {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 125%;
        letter-spacing: 0.01em;
        opacity: 0.5;
        position: absolute;
        top: 7px;
        right: 0;
    }

    & > div {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.form_default--slider-item {
    width: auto;
    display: block;
    position: relative;
    padding: 22px 8px 0;
    text-align: center;
    font-size: 11px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.01em;
    @include anim-f(color);
    cursor: pointer;
    box-sizing: content-box;
    min-width: 16px;

    @include bp(lg) {
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.01em;
        padding-top: 26px;
    }

    &:hover {
        color: $color-accent;

        &:after {
            border-color: $color-accent;
        }
    }

    &:before {
        @include pseudos;
        position: absolute;
        height: 4px;
        top: 2px;
        right: 50%;
        width: 100vw;
        background-color: $color-accent;
        z-index: 2;
        pointer-events: none;
        opacity: 0;
        @include anim-f(opacity);

        @include bp(lg) {
            top: 6px;
        }
    }

    &:after {
        @include pseudos;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-light;
        border: 1px solid rgba($color-raw-dark, 0.5);
        position: absolute;
        left: 50%;
        margin-left: -4px;
        top: 0;
        z-index: 3;
        @include anim-f(background, border, transform);

        @include themeDark {
            border-color: rgba($color-raw-reverse-dark, 0.5);
        }

        @include bp(lg) {
            width: 16px;
            height: 16px;
            transform-origin: 50% 50%;
            transform: scale(0.75);
            margin-left: -8px;
        }
    }

    &:first-of-type {
        margin-right: -8px;

        &:after {
            box-shadow: 4px 0 0 $color-light, 8px 0 0 $color-light, 12px 0 0 $color-light, 16px 0 0 $color-light;
        }
    }

    &:last-of-type {
        margin-left: -8px;

        &:after {
            box-shadow: -4px 0 0 $color-light, -8px 0 0 $color-light, -12px 0 0 $color-light, -16px 0 0 $color-light;
        }
    }

    & > strong {
        font-weight: 400;

        @include bp(md) {
            font-weight: 700;
        }
    }

    & > small {
        font-size: inherit;
        line-height: inherit;
    }

    &.checked {
        color: $color-accent;

        &:after {
            background-color: $color-accent;

            @include bp(lg) {
                transform: scale(1);
            }
        }

        &,
        & ~ .form_default--slider-item {
            &:after {
                border-color: $color-accent;
            }

            &:before {
                opacity: 1;
            }
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        @include hideField;
    }
}
