.page_main {
    &--block_rate {
        padding-bottom: 40px;

        &-sliders {
            @include bp(lg) {
                width: 59.5%;
                flex-shrink: 0;
                margin-right: 20px;
                padding: 0 40px 40px;
                position: relative;
                z-index: 1;
                overflow: hidden;

                &:after {
                    @include pseudos;
                    position: absolute;
                    border-radius: 10px;
                    box-shadow: 400px -10px 0 0 $color-light, inset 0 0 0 100vw $color-light;
                    z-index: -1;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    top: 52px;
                    border: 1px solid transparent;
                }
            }

            &:before {
                @include pseudos;
                @extend %bg-gradient;
                border-radius: 10px;
                @include stretch;
                z-index: -3;

                @include bp-to(lg) {
                    display: none;
                }
            }

            .form_default--tab {
                @include bp(lg) {
                    margin-left: -40px;
                    margin-right: -40px;
                    width: auto;

                    &:after {
                        @include pseudos;
                        flex-grow: 1;
                        align-self: stretch;
                        background-color: $color-light;
                        margin-bottom: 1px;
                        z-index: -3;
                        margin-left: 1px;
                    }

                    & > label {
                        min-width: 150px;
                        margin-right: -1px;
                        cursor: pointer;

                        span {
                            height: 53px;
                            border-bottom: none;
                            line-height: 53px;
                            padding-left: 5px;
                            padding-right: 5px;
                            text-align: center;
                            width: 100%;

                            svg {
                                position: static;
                                display: inline-block;
                                vertical-align: middle;
                                margin-top: -3px;
                            }

                            &:before {
                                @include pseudos;
                                @include stretch;
                                border-radius: 10px 10px 0 0;
                                z-index: -2;
                                box-shadow: inset 0 0 0 100vw $color-light, 0 -10px 0 0 $color-light;
                                border-width: 1px;
                                border-style: solid;
                                border-color: transparent;
                            }
                        }

                        &:not(:first-of-type) {
                            input[type="radio"] {
                                &:not(:checked) ~ span {
                                    &:before {
                                        clip-path: polygon(
                                            1px 0,
                                            calc(100% + 0.5px) 0,
                                            calc(100% + 0.5px) 100%,
                                            1px 100%
                                        );
                                    }
                                }
                            }
                        }

                        &:first-of-type {
                            input[type="radio"] {
                                &:not(:checked) ~ span {
                                    &:before {
                                        clip-path: polygon(0 0, calc(100% - 0.5px) 0, calc(100% - 0.5px) 100%, 0 100%);
                                    }
                                }
                            }

                            & > span {
                                &:after {
                                    @include pseudos;
                                    width: auto;
                                    position: absolute;
                                    left: 1px;
                                    right: 1px;
                                    height: 10px;
                                    bottom: -10px;
                                    background-color: $color-light;
                                    pointer-events: none;
                                }
                            }
                        }
                    }

                    input[type="radio"] {
                        &:checked ~ span {
                            &:before {
                                border-bottom-width: 0;
                                box-shadow: inset 0 0 0 100vw $color-light, 0 -10px 0 0 $color-light;
                            }
                        }

                        &:not(:checked) ~ span {
                            &:before {
                                border-color: $color-gray $color-gray transparent;
                            }
                        }
                    }
                }
            }
        }

        &-total {
            margin-top: 100px;
            padding: 30px 20px;
            @extend %shadow-default;
            @extend %border-gradient;
            position: relative;
            z-index: 1;

            @include bp(lg) {
                margin-top: 53px;
                padding: 40px;
                width: calc(40.5% - 20px);
            }

            h3 {
                @extend %header-medium;
                margin-bottom: 40px;
            }

            &:after {
                @include pseudos;
                z-index: -2;
                @extend %bg-gradient;
                width: 100vw;
                height: 100%;
                left: 50%;
                margin-left: -50vw;
                bottom: -40px;
                position: absolute;

                @include bp(lg) {
                    display: none;
                }
            }

            p {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin: 10px 0;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 125%;
                letter-spacing: 0.01em;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                & > * {
                    &:last-child {
                        text-align: right;
                        margin-left: 15px;
                        flex-shrink: 0;
                    }

                    & > s {
                        opacity: 0.5;
                    }
                }

                & > .input_container {
                    margin-bottom: 0;
                }

                & > strong {
                    font-family: $font2;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 125%;
                    letter-spacing: 0.01em;

                    & > span,
                    & > small {
                        font-family: $font1;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 1.25em;
                        letter-spacing: 0.01em;
                    }

                    & > small {
                        font-weight: 400;
                    }
                }

                & > small {
                    font-size: inherit;
                    line-height: inherit;
                }
            }

            footer {
                padding-top: 20px;
                margin-top: 20px;
                border-top: 1px solid $color-gray;

                & > p {
                    margin: 20px 0;

                    &:first-child {
                        margin-top: 0;
                    }

                    & > strong {
                        padding: 10px 0;
                    }
                }
            }
        }

        .block_default--content {
            @include bp(lg) {
                z-index: 1;
                position: relative;

                .form_default {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-wrap: nowrap;
                }
            }
        }

        &-bg {
            z-index: -1;
            display: none;

            @include bp(lg) {
                display: block;
                position: absolute;
                width: 100vw;
                left: 50%;
                margin-left: -50vw;
                bottom: -40px;
                top: 150px;
            }

            &:before {
                @include pseudos;
                @include stretch;
                @extend %bg-gradient;
            }

            &:after {
                @include pseudos;
                @include stretch;
                background-image: inherit;
                background-size: auto 365px;
                background-position: 0 calc(100% - 12px);
                background-repeat: repeat-x;
            }
        }
    }

    &--block_connect {
        &-slider {
            .swiper-container {
                overflow: visible;
            }

            &-item {
                width: 300px;
                margin-right: 30px;
                margin-top: 40px;
                @extend %shadow-default;
                background-color: $color-light;
                padding: 0 20px 30px;
                position: relative;
                display: block;

                @include bp(md) {
                    margin-right: 25px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: stretch;
                    height: auto;
                    width: 370px;
                    padding: 0 30px 30px;
                    @include anim-s(transform);

                    &:hover {
                        transform: translateY(-10px);
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                & > span {
                    border-radius: 10px 10px 0 0;
                    width: auto;
                    margin: 0 -20px 20px;
                    position: relative;
                    display: block;
                    background: radial-gradient(
                            circle at 70% 180%,
                            #{rgba($color-raw-accent-alt, 0.2)},
                            #{rgba($color-raw-accent-alt, 0.2)} 65%,
                            transparent 65.01%
                        ),
                        radial-gradient(
                            circle at 20% 170%,
                            #{rgba($color-raw-accent-alt, 0.2)},
                            #{rgba($color-raw-accent-alt, 0.2)} 65%,
                            transparent 65.01%
                        ),
                        $color-accent;
                    height: 163px;

                    @include bp(md) {
                        margin: 0 -30px 20px;
                        height: 202px;
                    }

                    img {
                        display: block;
                        position: absolute;
                        top: -40px;
                        left: 0;
                        object-fit: contain;
                        object-position: 50% 100%;
                        width: 100% !important;
                        height: calc(100% + 40px) !important;

                        @include bp(md) {
                            top: -53px;
                            height: calc(100% + 53px) !important;
                        }
                    }
                }

                h3 {
                    @extend %header-medium;
                    text-align: center;
                    margin-bottom: 30px;
                }

                p {
                    margin-bottom: 25px;
                    position: relative;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 125%;
                    letter-spacing: 0.01em;
                    padding-left: 50px;

                    &:last-of-type {
                        margin-bottom: 30px;
                    }

                    svg {
                        fill: $color-dark;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }

                .btn {
                    @include bp(md) {
                        margin-top: auto;
                    }
                }
            }
        }
    }

    &--block_features {
        @include bp(xl) {
            padding: 100px 0 50px;
        }

        .wrapper {
            @include bp(md, xl) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;

                & > * {
                    width: calc(50% - 15px);
                    flex-shrink: 0;
                    flex-grow: 0;
                }
            }
        }

        .block_default--bg {
            background-size: auto;
            background-position: 50% 50%;
            background-repeat: repeat-x;
        }

        &-circle {
            @include bp(xl) {
                fill: none;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-top: -267px;
                margin-left: -267px;
                stroke: $color-accent;
                stroke-width: 4px;
                font-size: 0;
                line-height: 0;
                z-index: 2;
                pointer-events: none;
                stroke-dasharray: 1662;
                stroke-dashoffset: 1662;
                transform-origin: 50% 50%;
                transform: translateZ(0) rotate(-90deg);

                svg {
                    @include anim-f(stroke-dashoffset);
                }
            }
        }

        &-nav,
        &-text {
            &-item {
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.3s ease-out, visibility 0.001s linear 0.3s !important;
                background-color: $color-light;

                &.swiper-slide-active {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: 0s !important;
                }
            }
        }

        &-nav {
            @include bp-to(md) {
                width: 100vw;
                left: 50%;
                margin-left: -50vw;
                margin-bottom: 40px;
            }

            @include bp(xl) {
                @include reset-slider;

                & > .swiper-container {
                    height: 530px !important;
                    width: 530px !important;
                    margin: 0 auto;
                    position: relative;

                    &:before {
                        @include pseudos;
                        @include stretch;
                        border-radius: 50%;
                        border: 2px solid $color-gray;
                        z-index: 1;
                    }
                }

                & > .swiper-container {
                    &,
                    & > .swiper-wrapper {
                        z-index: unset !important;
                    }
                }
            }

            & > .swiper-container {
                &:before {
                    background-color: rgba($color-raw-light, 0.7);

                    @include themeDark {
                        background-color: rgba($color-raw-reverse-light, 0.7);
                    }
                }
            }

            &-item {
                cursor: pointer;

                @include bp-to(xl) {
                    @include aspect-ratio(375, 290);
                    overflow: hidden;
                    left: auto !important;
                    top: auto !important;
                }

                @include bp(md, xl) {
                    border-radius: 10px;
                }

                @include bp(xl) {
                    visibility: visible !important;
                    opacity: 1 !important;
                    transition: none !important;
                    background-color: transparent !important;
                    width: 0 !important;
                    height: 0 !important;
                    position: absolute !important;
                    position: relative;
                    z-index: 3;

                    &:before {
                        @include pseudos;
                        @include centre;
                        @include anim-f(border, background);
                        background-color: $color-accent;
                        border-color: $color-accent;
                        border-radius: 50%;
                        width: 14px;
                        height: 14px;
                    }

                    .swiper-slide-active ~ & {
                        &:before {
                            background-color: $color-light;
                            border: 1px solid $color-gray;
                        }
                    }

                    &.swiper-slide-active {
                        img {
                            transform: translateZ(0) scale(1);
                            opacity: 1;
                        }

                        & > span {
                            margin: 55px;
                        }
                    }

                    &:hover {
                        & > span {
                            color: $color-accent;
                        }
                    }

                    &.more_half {
                        & > span {
                            left: auto;
                            right: 100%;
                        }
                    }

                    &.bottom_text {
                        & > span {
                            bottom: auto;
                            top: 100%;
                        }
                    }
                }

                & > span {
                    display: none;

                    @include bp(xl) {
                        display: block;
                        position: absolute;
                        margin: 17px;
                        width: 180px;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 125%;
                        letter-spacing: 0.01em;
                        bottom: 100%;
                        left: 100%;
                        @include anim-f(color, margin);
                    }
                }

                img {
                    object-fit: cover;

                    @include bp(xl) {
                        width: 140px !important;
                        height: 140px !important;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin: -70px;
                        transform: translateZ(0) scale(0);
                        opacity: 0;
                        @include anim-f(transform, opacity);
                    }
                }
            }
        }

        &-text {
            padding-bottom: 50px;

            @include bp(xl) {
                width: 350px;
                position: absolute;
                @include centre;
                text-align: center;
                padding-bottom: 0;
                z-index: 4;

                .swiper-pagination,
                .swiper-button-prev,
                .swiper-button-next {
                    display: none;
                }
            }

            &-item {
                color: rgba($color-raw-dark, 0.7);

                @include themeDark {
                    color: rgba($color-raw-reverse-dark, 0.7);
                }

                @include bp(xl) {
                    background-color: transparent !important;

                    & > * {
                        opacity: 0;
                        visibility: hidden;
                        transition: opacity 0.3s ease-out, visibility 0.001s linear 0.3s;
                    }

                    &.swiper-slide-active {
                        & > * {
                            opacity: 1;
                            visibility: visible;
                            transition-delay: 0s;
                        }
                    }
                }

                h3 {
                    @extend %header-medium;
                    margin-bottom: 20px;
                    color: $color-dark;
                }
            }
        }
    }

    &--block_map {
        &-head {
            @keyframes mapHeaderLine {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(-500px);
                }
            }

            @keyframes mapHeaderLineLg {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(-1000px);
                }
            }

            h2 {
                font-family: $font2;
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                line-height: 125%;
                letter-spacing: 0.01em;
                margin-bottom: 60px;
                white-space: nowrap;
                display: inline-block;
                width: auto;
                text-shadow: 500px 0 0 $color-dark, 1000px 0 0 $color-dark;
                animation: mapHeaderLine 7s linear infinite;
                padding-right: 150px;

                @include bp(lg) {
                    font-weight: 500;
                    font-size: 70px;
                    padding-right: 200px;
                    text-shadow: 1000px 0 0 $color-dark, 2000px 0 0 $color-dark, -1000px 0 0 $color-dark;
                    animation: mapHeaderLineLg 10s linear infinite;
                }
            }
        }

        &-map {
            position: relative;
            width: 100vw;
            left: 50%;
            margin-left: -50vw;
            margin-top: 40px;

            &:focus {
                iframe {
                    pointer-events: all;
                }
            }

            iframe {
                width: 100% !important;
                pointer-events: none;

                @include bp(lg) {
                    min-height: 600px;
                }
            }
        }
    }
}
