.block_news {
    .block_default--header,
    .block_default--foot {
        animation: fadeIn 0.3s ease-out both paused 0.3s;

        body.is-ie &,
        &.anim-inViewport {
            animation-play-state: running;
            visibility: visible;
            opacity: 1;
        }
    }

    &--slider {
        .swiper-container {
            overflow: visible;
        }

        &-item {
            display: block;
            @extend %shadow-default;
            background-color: $color-light;
            width: 300px;
            padding: 0 20px 20px;
            margin-right: 30px;
            visibility: hidden;
            top: 100px;
            transition: transform 1s ease, top 0.3s ease-out, opacity 0.3s ease-out;
            opacity: 0;

            @include bp(md) {
                margin-right: 25px;
                width: 370px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: stretch;
                height: auto;
            }

            @include bp(xl) {
                &:hover {
                    transform: translateY(-10px);
                }
            }

            &.anim-inViewport,
            body.is-ie & {
                visibility: visible;
                top: 0;
                opacity: 1;

                @for $i from 0 to 6 {
                    &.anim-order#{$i} {
                        transition: transform 1s ease, top 0.3s ease-out #{$i * 0.1s + 0.3s},
                            opacity 0.3s ease-out #{$i * 0.1s + 0.3s};
                    }
                }
            }

            &:last-child {
                margin-right: 0;
            }

            & > span {
                display: block;
                position: relative;
                width: auto;
                margin: 0 -20px 20px;
                height: 180px;
                overflow: hidden;
                border-radius: 10px 10px 0 0;

                @include bp(md) {
                    height: 220px;
                }

                img {
                    @include stretch;
                    object-fit: cover;
                }
            }

            time {
                display: block;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 125%;
                letter-spacing: 0.01em;
                opacity: 0.5;
                margin-bottom: 15px;
            }

            h3 {
                @extend %header-medium;
                margin-bottom: 7px;
            }

            & > * {
                &:nth-last-child(2) {
                    margin-bottom: auto;
                }
            }

            .btn {
                margin-top: 30px;
            }
        }
    }
}
