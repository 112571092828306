.page_support {
    &--block_form {
        @include bp(lg) {
            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
            }
        }

        @include bp(lg, xl) {
            .wrapper {
                & > * {
                    width: calc(50% - 20px);
                    flex-grow: 0;
                    flex-shrink: 0;
                }
            }
        }

        .block_default--header {
            position: relative;
            margin-bottom: 80px;
            z-index: 1;
            padding: 40px 0;

            @include bp(lg) {
                padding: 40px;

                &:before {
                    width: 100%;
                    left: 0;
                    margin-left: 0;
                    border-radius: 10px;
                }
            }

            @include bp(xl) {
                width: 630px;
                margin-right: 60px;
                flex-grow: 0;
                flex-shrink: 0;
            }

            &:before {
                @include pseudos;
                z-index: -1;
                width: 100vw;
                height: 100%;
                left: 50%;
                margin-left: -50vw;
                top: 0;
                @extend %bg-form;
                position: absolute;
            }

            .form_default {
                margin-top: 40px;
                display: block;

                .btn {
                    @include bp-to(md) {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }

        .block_default--content {
            @include bp(lg) {
                margin-top: 125px;
            }

            @include bp(xl){
                width: 380px;
                margin-right: auto;
            }

            h3 {
                @extend %header-medium;
                margin-bottom: 40px;
            }

            a {
                font-style: normal;
                font-size: 18px;
                line-height: 125%;
                letter-spacing: 0.01em;

                &[href^="tel"],
                &[href^="mailto"] {
                    font-weight: 700;
                }

                i {
                    background-color: $color-dark;
                    color: $color-light;
                    font-size: 16px;
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    line-height: 24px;
                    text-align: center;
                    border-radius: 50%;
                    margin-right: 5px;
                    @include anim-f(background);
                }

                &:hover {
                    color: $color-accent;

                    i {
                        background-color: $color-accent;
                    }
                }
            }
        }
    }

    &--block_faq{
        .wrapper{
            max-width: 766px;
        }

        h3{
            @extend %header-medium;
            color: $color-accent;
            margin-top: 40px;
            margin-bottom: 20px;

            &:first-child{
                margin-top: 0;
            }
        }
    }
}
