@include font-face("Calibri", "./fonts/Calibri-Light", $file-formats: eot ttf woff woff2) {
    font-weight: 300;
    font-style: normal;
}
@include font-face("Calibri", "./fonts/Calibri", $file-formats: eot ttf woff woff2) {
    font-weight: 400;
    font-style: normal;
}
@include font-face("Calibri", "./fonts/Calibri-Bold", $file-formats: eot ttf woff woff2) {
    font-weight: 700;
    font-style: normal;
}
@include font-face("Magistral", "./fonts/Magistral-Medium", $file-formats: eot ttf woff woff2) {
    font-weight: 500;
    font-style: normal;
}
@include font-face("Magistral", "./fonts/Magistral-Bold", $file-formats: eot ttf woff woff2) {
    font-weight: 700;
    font-style: normal;
}

$font1: "Calibri", sans-serif;
$font2: "Magistral", sans-serif;
