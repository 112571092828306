.page_documents{
    &--block_list{
        .block_default--header{
            text-align: left;

            h1{
                @extend %header-large;
            }
        }

        .block_default--content{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: -40px;
            flex-wrap: wrap;
        }

        &-item{
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 125%;
            letter-spacing: 0.01em;
            min-height: 60px;
            padding-left: 75px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: auto;
            margin-bottom: 40px;
            width: 100%;
            @include anim-f(color);

            @include bp(md){
                width: calc(50% - 20px);
                margin-right: 40px;
            }

            @include bp(md, lg){
                &:nth-child(2n){
                    margin-right: 0;
                }
            }

            @include bp(lg){
                width: calc(33.333% - 26.6666px);
                
                &:nth-child(3n){
                    margin-right: 0;
                }
            }

            &:hover{
                color: $color-accent;
            }

            small{
                font-size: inherit;
                line-height: inherit;
                color: $color-dark;
                opacity: 0.7;
                white-space: nowrap;
            }
        }
    }
}