.breadcrumbs {
    font-size: 0;
    line-height: 0;
    letter-spacing: 0;

    @include bp(lg) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
    }

    & > * {
        display: none;
        font-style: normal;
        font-weight: normal;
        color: $color-gray;

        @include bp-to(lg) {
            &[data-back] {
                display: inline-block;
                color: $color-gray !important;
                @include anim-f(opacity);
                padding-left: 36px;
                position: relative;

                &:before {
                    @include pseudos;
                    @include arrow-left-gray;
                    background-position: 0 0;
                    background-size: contain;
                    position: absolute;
                    left: 0;
                    top: 4px;
                }

                &:after {
                    content: attr(data-back);
                    font-size: 16px;
                    line-height: 125%;
                    letter-spacing: 0.01em;
                }
            }
        }

        @include bp(lg) {
            display: inline-block;
            position: relative;
            margin-right: 17px;
            white-space: nowrap;
            flex-shrink: 1;
            text-overflow: ellipsis;
            font-size: 16px;
            line-height: 125%;
            letter-spacing: 0.01em;

            &:last-child {
                overflow: hidden;
                color: rgba($color-raw-dark, 0.5);
                margin-right: 0;

                &:after {
                    display: none;
                }
            }

            &:after {
                content: "/";
                display: block;
                position: absolute;
                top: 0;
                left: 100%;
                margin-left: 6px;
                pointer-events: none;
                color: $color-dark;
                opacity: 0.5;
            }

            &:hover {
                color: $color-accent !important;
            }
        }

        @include themeDark {
            &:last-child {
                color: rgba($color-raw-reverse-dark, 0.5) !important;
            }
        }
    }
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgba($color-raw-dark, 0.7);
    text-align: center;

    @include themeDark {
        color: rgba($color-raw-reverse-dark, 0.7);
    }

    & > * {
        margin: 0 10px;
    }

    & > a {
        &:hover {
            color: $color-accent;
        }
    }

    &-active {
        color: $color-accent;
        font-weight: 700;
    }

    & > .pagination-prev {
        margin-left: 0;
        margin-right: 20px;
    }

    & > .pagination-next {
        margin-right: 0;
        margin-left: 20px;
    }

    &-prev,
    &-next {
        display: block;
        position: relative;
        width: 15px;
        height: 30px;
        font-size: 0;
        padding: 0;
        left: auto;
        transform: none;
        background-color: transparent;
        border: none;
        @include anim-f(opacity);

        &:before {
            @include pseudos;
            @include arrow(24px, 2px, $color-dark, right);
            top: 50%;
            right: 5px;
            margin-top: -12px;
            position: absolute;
            @include anim-f(border-color);
        }

        &:hover {
            &:before {
                border-color: $color-accent;
            }
        }

        &.disabled{
            opacity: 0.5;
            pointer-events: none;
        }
    }

    span.pagination-prev,
    span.pagination-next {
        pointer-events: none;
        opacity: 0.5;
    }

    &-prev {
        transform-origin: 50% 50%;
        transform: scaleX(-1);
        margin-left: 0;
    }
}

.social_links {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    & > a {
        margin-right: 20px;
        display: inline-block;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: relative;
        font-size: 14px;
        background-color: $color-dark;
        @include anim-f(color, background);

        i {
            @include centre(both, true);
            color: $color-light;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background-color: $color-accent;
        }
    }
}

.social_link {
    margin: -3px 0 -3px 10px;
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: relative;
    font-size: 16px;
    background-color: $color-accent;
    @include anim-f(color, background);
    vertical-align: top;

    i {
        @include centre(both, true);
        color: $color-light;
    }

    &:hover {
        background-color: $color-dark;
    }
}
