.tabs {
    &-tab {
        visibility: hidden;
        z-index: -999;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        opacity: 0;
        width: 100%;

        &.tabs-active {
            visibility: visible;
            z-index: 1;
            position: static;
            top: auto;
            left: auto;
            pointer-events: all;
            animation: fadeIn 0.2s ease-out;
            opacity: 1;
        }
    }

    &-nav {
        font-size: 0;
        text-align: center;
        margin-bottom: 40px;

        .swiper-container {
            overflow: visible;
        }

        &-item {
            display: inline-block;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 125%;
            letter-spacing: 0.01em;
            color: $color-dark;
            white-space: nowrap;
            opacity: 0.5;
            padding-bottom: 3px;
            border-bottom: 2px solid $color-accent;
            margin-right: 30px;
            @include anim-f(opacity, border, color);
            width: auto;

            @include bp(lg){
                font-weight: 500;
                font-size: 24px;
                line-height: 125%;
                letter-spacing: 0.01em;
                margin-right: 40px;
                font-family: $font2;
            }

            &:last-child {
                margin-right: 0;
            }

            &.tabs-active {
                color: $color-accent;
            }

            &:hover,
            &.tabs-active {
                opacity: 1;
            }

            &:not(.tabs-active) {
                border-color: rgba($color-raw-accent, 0);
            }
        }
    }
}
