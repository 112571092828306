.block_head {
    margin: 20px 0 80px;

    @include bp(lg) {
        margin: 40px 0 120px;
    }

    .breadcrumbs {
        margin-bottom: 40px;

        @include bp(lg) {
            margin-bottom: 0;
            position: absolute;
            left: 0;
            top: 0;
            max-width: 50%;
            z-index: 2;
        }
    }

    .row {
        margin-bottom: -40px;

        & > * {
            margin-bottom: 40px;
            position: relative;

            &:last-child {
                @include bp(md) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                }
            }
        }
    }

    &--img {
        position: relative;

        @include bp-to(md) {
            @include aspect-ratio(375, 250);
            width: 100vw;
            left: 50%;
            margin-left: -50vw;
        }

        @include bp(md) {
            width: 100%;
            height: 300px;
            border-radius: 10px;
            overflow: hidden;
            position: sticky;
            top: 90px;
            margin-bottom: 40px;
            right: 0;
        }

        @include bp(lg) {
            height: 450px;
        }

        img {
            @include stretch;
            object-fit: cover;
        }

        & > *{
            @include stretch;
        }
    }

    &--text {
        @include bp(lg) {
            padding: 60px 50px 60px 0;
        }

        h5 + h1,
        time + h1 {
            margin-top: 0;
        }
    }
}
