.block_form {
    @extend %bg-form;
    @extend %shadow-default;
    padding: 40px 20px;

    @include bp(md){
        text-align: center;
        padding: 40px 60px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1026px;
    }

    h2,
    h3 {
        @extend %header-medium;
        margin-bottom: 10px;
    }

    .form_default{
        margin-top: 30px;

        .row{
            margin-top: -20px;
            margin-bottom: 0;

            & > *{
                margin-bottom: 0;
                margin-top: 20px;
            }
            @include bp-to(md){
                & > *{
                    &:last-child{
                        margin-top: 40px;
                    }
                }
            }

            @include bp(lg){
                flex-wrap: nowrap;

                & > *:last-child{
                    margin-left: 20px;
                }

                & > *:first-child{
                    flex-shrink: 0;
                    width: 300px;
                }
            }
        }
    }
}
