$screen-min: 320px;

// z-indexes
$z-header: 10;
$z-popup: 20;
$z-select: 30;
$z-tooltip: 8;

// Colors
$color-raw-light: #fafafa;
$color-raw-dark: #1e1f20;
$color-raw-gray: #cdcdcd;
$color-raw-gray2: #EDEDED;
$color-raw-accent: #ff7300;
$color-raw-accent-hover: #ee6e04;
$color-raw-accent-alt: #ffeb00;
$color-raw-red: #ed4242;

$color-raw-reverse-light: #3a3a3a;
$color-raw-reverse-dark: #fafafa;
$color-raw-reverse-gray: #a8a8a8;
$color-raw-reverse-gray2: #222222;

:root {
    --colorLight: #{$color-raw-light};
    --colorDark: #{$color-raw-dark};
    --colorGray: #{$color-raw-gray};
    --colorGrayAlt: #{$color-raw-gray2};
}

$color-light: var(--colorLight);
$color-dark: var(--colorDark);
$color-gray: var(--colorGray);
$color-gray-alt: var(--colorGrayAlt);
$color-accent: #{$color-raw-accent};
$color-accent-hover: #{$color-raw-accent-hover};
$color-accent-alt: #{$color-raw-accent-alt};
$color-red: #{$color-raw-red};

@mixin themeDark {
    body.theme_dark{
        @content;
    }
    // @media (prefers-color-scheme: dark) {
    //     @content;
    // }
}

@include themeDark {
    :root {
        --colorLight: #{$color-raw-reverse-light};
        --colorDark: #{$color-raw-reverse-dark};
        --colorGray: #{$color-raw-reverse-gray};
        --colorGrayAlt: #{$color-raw-reverse-gray2};
    }
}

%bg-form {
    background: linear-gradient(26.82deg, rgba(255, 115, 0, 0.1) 12.73%, rgba(255, 235, 0, 0.1) 92.73%),
        linear-gradient(0deg, rgba(255, 115, 0, 0.05), rgba(255, 115, 0, 0.05)), #ffffff;

    @include themeDark {
        background: linear-gradient(26.82deg, rgba(255, 115, 0, 0.05) 12.73%, rgba(255, 235, 0, 0.05) 92.73%),
            linear-gradient(0deg, rgba(255, 115, 0, 0.05), rgba(255, 115, 0, 0.05)), #353535;
    }
}

%shadow-default {
    box-shadow: 5px 5px 22px rgba($color-raw-dark, 0.1);
    border-radius: 10px;

    @include themeDark {
        box-shadow: 5px 5px 22px rgba(0, 0, 0, 0.1);
    }
}

%bg-gradient {
    background: linear-gradient(
            26.82deg,
            #{rgba($color-raw-accent, 0.6)} 12.73%,
            #{rgba($color-raw-accent-alt, 0.6)} 92.73%
        ),
        $color-light;
}

%border-gradient {
    background-color: white;

    &:before {
        @include pseudos;
        @include stretch;
        z-index: -1;
        background: linear-gradient(
                26.82deg,
                #{rgba($color-raw-accent, 0.6)} 12.73%,
                #{rgba($color-raw-accent-alt, 0.6)} 92.73%
            ),
            $color-light;
        background-position: -1px -1px;
        background-size: calc(100% + 2px) calc(100% + 2px);
        border-radius: inherit;
        border: 1px solid transparent;
        box-shadow: inset 0 0 0 100vw $color-light;
    }
}
