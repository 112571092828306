$grid-col-count: 12;
$grid-col-width: 100% / $grid-col-count;
$grid-col-gap: 20px;
$grid-col-bottom-gap: 20px;

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: auto;
    margin: 0 -#{$grid-col-gap/2} -$grid-col-bottom-gap;

    &-middle {
        align-items: center;
    }

    &-bottom {
        align-items: flex-end;
    }

    &-stretch {
        align-items: stretch;
    }

    &-justify {
        justify-content: space-between;
    }

    & > * {
        box-sizing: border-box;
        margin-bottom: $grid-col-bottom-gap;
        border-style: solid;
        border-color: transparent;
        border-width: 0 #{$grid-col-gap/2};
        position: relative;
    }
}

.col {
    flex-basis: 100%;

    @include bp(sm, xl) {
        flex-basis: 50%;
    }

    @include bp(xl) {
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 0;
    }

    &-breaker{
        width: 100% !important;
        flex-basis: 100% !important;
        margin: 0 !important;
    }
}

@mixin setGridParam($param-name, $colNum) {
    #{$param-name}: #{$colNum * $grid-col-width};
}

@each $bp, $val in $breakpoints {
    *[class*="col-#{$bp}"] {
        @include bp($bp) {
            flex-basis: auto !important;
            flex-grow: 0;
        }
    }
}

@for $i from 1 to 13 {
    .col-#{$i} {
        width: #{$grid-col-width * $i};
        flex-basis: auto;
        flex-grow: 0;
    }

    @each $bp, $val in $breakpoints {
        @include bp($bp) {
            .col-#{$bp}-#{$i} {
                @include setGridParam(width, $i);
            }

            .order-#{$bp}-#{$i} {
                order: $i;
            }
        }
    }
}
