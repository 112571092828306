/**
В данный файл можно писать стили для различных кнопок.
*/

button,
input[type="submit"] {
    appearance: none;
    padding: 0;
    border: none;
    display: block;
    background-color: transparent;
    border-radius: 0;
    outline: none;
    box-shadow: none;
}

.btn {
    display: block;
    width: 100%;
    @include anim-f(all);
    padding: 12px 38px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 125%;
    text-align: center;
    color: $color-light;
    border: 2px solid $color-accent;
    background-color: $color-accent;
    border-radius: 5px;

    &:hover {
        background-color: $color-accent-hover;
        border-color: $color-accent-hover;
    }

    &.inline {
        display: inline-block;
        width: auto;
    }

    &:disabled,
    &.disabled {
        // pointer-events: none;
        background-color: $color-gray;
        border-color: $color-gray;
    }

    &.light {
        background-color: transparent;
        color: $color-accent;

        &:hover {
            border-color: $color-accent-hover;
            background-color: $color-accent-hover;
            color: $color-light;
        }

        &:disabled {
            &,
            &.darken {
                color: $color-gray;
                border-color: $color-gray;
                background-color: transparent;
                opacity: 1;
            }
        }

        &.darken {
            color: $color-dark;
            border-color: $color-dark;
            opacity: 0.5;

            &:hover {
                opacity: 1;
                border-color: $color-dark;
                color: $color-dark;
                background-color: transparent;
            }
        }
    }
}
