$modalShowDur: 0.3s;

.popup {
    &-root {
        position: fixed;
        //z-index: -999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        // Костыль для анимации.
        transition: opacity $modalShowDur ease-in-out, z-index 0.001s linear $modalShowDur,
            visibility 0.001s linear $modalShowDur;
        z-index: -999;
        //display: table;
        display: block;
        visibility: hidden;

        &.popup-visible {
            z-index: $z-popup;
            opacity: 1;
            visibility: visible;
            overflow-y: auto;
            transition-delay: 0s;
            animation: fadeIn $modalShowDur ease-out;
        }

        &:not(.popup-visible) {
            animation: none !important;
        }
    }

    &-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($color-raw-dark, 0.8);
        @include animation-fix;

        @include themeDark {
            background-color: rgba($color-raw-reverse-light, 0.8);
        }
    }

    &-cross {
        width: 30px;
        height: 30px;
        top: 20px;
        right: 20px;
        @include cross(34px, 1px, $color-accent, 0, $color-accent-hover);
        position: absolute;
        display: block;
        z-index: 2;

        @include bp(md) {
            top: 30px;
            right: 30px;
        }
    }

    &-container {
        position: relative;
        min-width: 100%;
        min-height: 100%;
        min-height: 100vh;
        z-index: 1;
        line-height: 90vh;
        padding: 5vh 0;
        text-align: center;

        .popup-non-scrollable & {
            padding-right: var(--scrollbarWidth, 17px);
        }
    }

    &-content {
        display: none;
        position: relative;
        z-index: 10;
        //transform: translate3d(0, 0, 0);
        vertical-align: middle;
        margin: 0 auto;
        width: #{(351/375) * 100%};
        text-align: center;
        font-family: $font1;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 125%;
        letter-spacing: 0.01em;
        padding: 60px 30px;
        @extend %bg-form;
        border-radius: 10px;

        @include bp(md) {
            width: 90%;
            padding: 100px;
            width: 700px;
        }

        .popup-initialized & {
            display: inline-block;
        }

        .form_default {
            margin-top: 30px;

            @include bp(md) {
                margin-top: 20px;
            }
        }
    }

    &-title {
        @extend %header-block;
        margin-bottom: 15px;
        display: block;
        color: $color-accent;
    }

    &-foot{
        text-align: center;
        display: block;
        margin-top: 30px;

        .btn{
            @include bp-to(md){
                width: 100%;
                display: block;
            }
        }
    }
}
