.form_default {
    select {
        display: block;
        width: 100%;
        height: 35px;
        border-radius: 7px;
        border: 1px solid rgba($color-dark, 0.2);
        @include anim-f(border);
        cursor: pointer;
        @extend %form-label;
        background-color: $color-light;
        padding: 12px 40px 12px 13px;
        height: 50px;
        // appearance: none;
        // background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.825 0.158203L5 3.97487L1.175 0.158203L0 1.3332L5 6.3332L10 1.3332L8.825 0.158203Z' fill='%23323232'/%3E%3C/svg%3E%0A");
        // background-repeat: no-repeat;
        // background-position: calc(100% - 21px) 50%;
        text-overflow: ellipsis;

        &:focus,
        &:hover {
            border-color: $color-dark !important;
        }

        &.validation-touched:invalid {
            border-color: $color-red;
        }
    }
}
