.block_text {
    @extend %collapse-margins;
    font-family: $font1;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 125%;
    letter-spacing: 0.01em;

    h1 {
        @extend %header-large;
        margin-bottom: 20px;
        margin-top: 40px;
    }

    h2 {
        @extend %header-block;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    h3 {
        @extend %header-medium;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    h4 {
        @extend %header-small;
        margin-bottom: 20px;
        margin-top: 40px;
    }

    h5,
    time {
        display: block;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 30px;
    }

    time{
        opacity: 0.5;
        font-weight: 400;
    }

    .row {
        & > * {
            @extend %collapse-margins;
        }
    }

    li {
        & > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    em{
        font-style: normal;
        color: $color-accent;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        & + p,
        & + ul,
        & + ol {
            margin-top: 0;
        }
    }

    li,
    p {
        a {
            @include bp-to(md) {
                &.btn.inline {
                    width: 100%;
                    display: block;
                }
            }

            &:not(.btn) {
                color: $color-dark;
                text-decoration: underline;

                &:hover {
                    color: $color-accent;
                }

                &[href^="tel"],
                &[href^="mailto"] {
                    font-weight: 700;
                    color: $color-accent;
                    text-decoration: none;
                }
            }

            & > small {
                display: inline-block;
                text-decoration: none;
                opacity: 0.7;
                margin-left: 0.2em;
                font-weight: 500;
                font-size: inherit;
                line-height: inherit;
                color: $color-dark;
            }
        }
    }

    p {
        img {
            display: block;
            max-width: 100%;
        }
    }

    strong,
    b {
        font-weight: 700;
    }

    p,
    ul,
    ol {
        margin: 1em 0;
    }

    ul {
        @extend %list-default;
        padding-left: 40px;
    }

    ol {
        @extend %list-numerated;
    }

    &--table {
        @include bp-to(lg) {
            width: 100%;
            display: block;
            overflow-x: auto;
            overflow-y: hidden;

            &-container {
                display: inline-block;
                width: auto;
            }

            table {
                td {
                    white-space: nowrap;
                }
            }
        }
    }

    &--img_row{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;

        & > img{
            margin-right: 20px;
            max-height: 46px;
            flex-shrink: 1;
            display: block;
            object-fit: contain;

            @include bp(md){
                margin-right: 30px;
            }

            &:last-child{
                margin-right: 0;
            }

            @include qq(3){
                max-width: calc(33.3333% - 16.6666px) !important;

                @include bp(md){
                    max-width: calc(33.3333% - 20px) !important;
                }
            }

            @include qq(2){
                max-width: calc(50% - 10px) !important;

                @include bp(md){
                    max-width: calc(50% - 15px) !important;
                }
            }
        }
    }

    &--address{
        @include bp(md){
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            & > span{
                flex-grow: 1;
                flex-shrink: 1;

                &:first-child{
                    margin-bottom: 0;
                    margin-right: 20px;
                    width: 120px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
            }
        }

        & > span{
            display: block;

            &:first-child{
                margin-bottom: 7px;
                color: $color-accent;
            }
        }
    }

    table {
        min-width: 100%;
    }
}
