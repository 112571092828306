.block_img {
    overflow: hidden;

    &--reversed {
        @include bp(md) {
            .block_default--header {
                order: 1 !important;
            }

            .block_default--content {
                order: 2 !important;
            }

            .block_img--img {
                float: left;
            }
        }

        @include bp(xl){
            .block_default--content{
                margin-right: 0 !important;
                width: 590px;
                margin-left: 70px;
            }
        }
    }

    .block_default--header {
        text-align: left;
        order: 2;
        margin-bottom: 0;
        margin-top: 40px;

        @include bp(md) {
            margin: 0;
        }

        @include bp(xl) {
            flex-grow: 1;
        }

        .block_text {
            max-width: none;
            margin: 0;
        }
    }

    &--buttons{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: -20px;
        flex-wrap: wrap;
        margin-top: 40px;

        & > *{
            margin-bottom: 20px;

            @include bp(lg){
                margin-right: 40px;

                &:last-child{
                    margin-right: 0;
                }
            }
        }

        .btn{
            @include bp-to(lg){
                width: 100%;
                display: block;
            }
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;

        @include bp(md) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .block_default--content {
                align-self: flex-start;
            }
        }

        @include bp(md, xl) {
            & > * {
                width: calc(50% - 15px);
                flex-shrink: 0;
            }
        }
    }

    .block_default--content {
        order: 1;
        @include clearfix;

        @include bp(xl) {
            width: 570px;
            margin-right: 98px;
        }
    }

    &--img {
        @include aspect-ratio(375, 290);
        overflow: hidden;
        border-radius: 0 10px 10px 0;
        width: 100vw;
        margin-left: -50vw;
        left: 50%;

        @include bp(md) {
            margin-left: 0;
            float: right;
            left: auto;
        }

        @include bp(md, xl) {
            width: 50vw;
        }

        @include bp(xl) {
            width: 740px;
            border-radius: 10px;
            @include aspect-ratio(740, 550);
        }

        @include bp-to(md){
            .block_default--gray &{
                margin-top: -60px;
            }

            .block_default--gray + .block_default--gray.block_img &{
                margin-top: 0;
            }
        }

        .block_img--reversed & {
            border-radius: 10px 0 0 10px;

            @include bp(xl) {
                border-radius: 10px;
            }
        }

        img {
            object-fit: cover;
            width: 100% !important;
            height: 100% !important;
        }
    }
}
