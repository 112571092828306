$wrapper-xs: 320px/375px * 100%;
$wrapper-md: 720px/768px * 100%;
$wrapper-xl: 1160px;

// $wrapperSizes: (
//     xs: 90%,
//     sm: rem(540px),
//     md: rem(720px),
//     lg: rem(960px),
//     xl: rem(1140px),
// );

body,
input,
button,
textarea {
    font-family: $font1;
    color: $color-dark;
    font-size: 18px;
    line-height: 125%;
    letter-spacing: 0.01em;
}

* {
    box-sizing: border-box;
}

html {
    position: relative;
    margin: 0;
    height: 100%;
    font-size: 16px;
    line-height: normal;
}

body {
    background: $color-light;
    margin: 0;

    @include bp-to(xl) {
        margin-right: 0 !important;
    }
}

main {
    flex-grow: 1;
}

.page-wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    width: 100%;
    min-width: $screen-min;
    position: relative;
}

.wrapper {
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    width: $wrapper-xs;

    @include bp(md, xl) {
        width: $wrapper-md;
    }

    @include bp(xl) {
        width: $wrapper-xl;
    }
}
