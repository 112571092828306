.form_default--tab {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    padding: 0;
    margin: 0 0 40px;

    label {
        display: block;
        margin-right: 30px;
    }

    input[type="radio"] {
        @include hideField;

        & ~ span {
            font-family: $font1;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 125%;
            letter-spacing: 0.01em;
            @include anim-f(color, border);
            position: relative;
            display: block;
            padding: 0 0 4px 26px;
            border-bottom: 2px solid rgba($color-raw-accent, 0);
            color: rgba($color-raw-dark, 0.5);

            @include themeDark {
                color: rgba($color-raw-reverse-dark, 0.5);

                svg {
                    fill: rgba($color-raw-reverse-dark, 0.5);
                }
            }

            svg {
                @include svgcolor(rgba($color-raw-dark, 0.5));
                position: absolute;
                left: 0;
                top: 1px;
                font-size: 20px;
            }
        }

        &:focus ~ span,
        & ~ span:hover {
            color: $color-dark;

            svg{
                fill: $color-dark;
            }
        }

        &:checked ~ span {
            opacity: 1;
            color: $color-accent;
            border-color: $color-accent;

            svg {
                fill: $color-accent;
            }
        }
    }
}
