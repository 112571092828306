.page_footer {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.01em;

    &--top {
        padding: 30px 0 88px;
        position: relative;
        border-bottom: 1px solid rgba($color-raw-dark, 0.1);

        @include bp(md) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            border-top: 1px solid rgba($color-raw-dark, 0.1);
        }

        @include bp(lg){
            padding: 40px 0 50px;
            flex-wrap: nowrap;
        }

        @include themeDark {
            border-color: rgba($color-raw-reverse-dark, 0.1) !important;
        }

        h3 {
            @extend %header-medium;
            color: $color-accent;
            margin-bottom: 15px;

            @include bp(lg){
                margin-bottom: 20px;
            }
        }

        .social_links {
            position: absolute;
            bottom: 30px;
            left: 0;
            width: 100%;

            @include bp(lg){
                position: static;
                margin-top: 70px;
            }
        }

        p {
            margin: 10px 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-col {
            margin-top: 30px;

            @include bp(md, lg) {
                @include first(2) {
                    width: calc(50% - 15px);
                    margin-top: 0;
                }

                &:last-child {
                    width: 100%;
                }
            }

            @include bp(lg){
                margin-top: 0;

                &:first-child{
                    width: 32.32%;
                    margin-right: 20px;
                }

                &:nth-child(2){
                    width: 29.39%;
                    margin-right: auto;
                }

                &:last-child{
                    max-width: 290px;
                    margin-left: 20px;
                }
            }

            &:first-child {
                margin-top: 0;
            }
        }

        .btn {
            @include bp(md) {
                margin-top: 5px;
            }

            @include bp-to(md) {
                width: 100%;
                display: block;
                margin-top: 15px;
            }
        }

        nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            & > * {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }

            a {
                display: inline-block;
                margin-bottom: 15px;

                &:hover {
                    color: $color-accent;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &--bottom {
        padding: 30px 0 20px;
        @extend %collapse-margins;

        @include bp(md){
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
            padding: 20px 0 35px;

            & > *{
                order: 2;

                &:nth-child(2){
                    order: 1;
                }
            }
        }

        @include bp(lg){
            & > *{
                &:nth-child(2){
                    width: 32.32%;
                    margin-right: 20px;
                }

                &:first-child{
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }

        p {
            margin: 10px 0;

            @include bp(md){
                margin: 0;
            }

            a {
                svg {
                    fill: rgba($color-raw-dark, 0.2);
                    @include anim-f(fill);

                    @include themeDark {
                        fill: rgba($color-raw-reverse-dark, 0.2);
                    }
                }

                &:hover {
                    color: $color-accent;

                    svg {
                        fill: $color-dark;
                    }
                }
            }
        }
    }
}
