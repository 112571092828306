.page_contacts {
    &--address {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 125%;
        letter-spacing: 0.01em;

        @include bp(lg){
            display: table;
            width: 100%;

            & > *{
                display: table-row;

                & > *{
                    display: table-cell;
                    padding-bottom: 10px;
                    vertical-align: middle;

                    &:first-child{
                        padding-right: 10px;
                        width: 190px;
                    }

                    &:last-child{
                        padding-left: 10px;
                    }
                }
            }
        }

        & > dl {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            & > dt {
                margin-bottom: 7px;
                display: block;
                opacity: 0.7;
            }

            & > dd {
                font-weight: 700;

                i {
                    font-weight: normal;
                }

                p {
                    margin: 0;
                    display: flex;
                    flex-direction: column;

                    @include bp(lg){
                        flex-direction: row;
                        flex-wrap: wrap;

                        & > *{
                            margin-bottom: 0;
                            margin-right: 20px;

                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }

                    & > *{
                        margin-bottom: 7px;

                        &:last-child{
                            margin-bottom: 0;
                        }
                    }

                    a {
                        color: $color-dark !important;
                    }
                }
            }
        }
    }
}
