.block_default {
    margin: 100px 0;
    position: relative;

    @include bp(lg) {
        margin: 140px 0;
    }

    .breadcrumbs{
        margin-bottom: 40px;
        margin-top: -60px;

        @include bp(lg){
            margin-top: -100px;
        }
    }

    &--gray{
        padding: 60px 0;
        background-color: $color-gray-alt;

        @include bp(lg){
            padding: 80px 0;
        }

        & + .block_default--gray{
            margin-top: -140px;

            @include bp(lg){
                margin-top: -200px;
            }
        }

        .block_default--header{
            .block_text{
                color: $color-dark;
            }
        }
    }

    &--bg {
        @include stretch;
        background-position: 50% 50%;
        background-size: cover;
        z-index: -1;
    }

    &--header {
        margin-bottom: 40px;

        @include bp(md) {
            text-align: center;
        }

        h1,
        h2 {
            @extend %header-block;
            margin-bottom: 20px;
        }

        .block_text {
            color: rgba($color-raw-dark, 0.7);

            @include themeDark {
                color: rgba($color-raw-reverse-dark, 0.7);
            }

            @include bp(md) {
                max-width: 550px;
                margin: 0 auto;
            }
        }

        & > .btn {
            margin-top: 30px;

            @include bp-to(md) {
                display: block;
                width: 100%;
            }
        }
    }

    &--foot {
        text-align: center;
        margin-top: 30px;

        .btn {
            @include bp-to(md) {
                width: 100%;
                display: block;
            }
        }
    }
}
