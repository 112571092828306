.page_error{
    @extend %bg-form;

    .page_footer{
        display: none;
    }

    main{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }

    &--layout{
        flex-grow: 1;
        flex-shrinK: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        padding: 80px 0;

        @include bp(md){
            text-align: center;
        }

        @include bp(lg){
            padding: 100px 0 140px;
        }

        img{
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            object-fit: contain;
            object-position: 50% 100%;
        }

        h1{
            @extend %header-large;
            margin-bottom: 20px;
            color: $color-accent;
        }

        .btn{
            margin-top: 50px;

            @include bp-to(md){
                display: block;
                width: 100%;
            }
        }
    }
}