.block_hero {
    $heroBgParallaxRatio: 0.2;

    @include bp(lg){
        margin-bottom: -40px;
    }

    &--text {
        color: $color-light;
        min-height: 720px;
        position: relative;
        width: 100%;
        padding: 180px 0 200px;
        z-index: 1;

        @include themeDark{
            color: $color-dark;
        }

        @include bp(md) {
            min-height: 620px;
            padding: 173px 0 200px;
        }

        @include bp(xl) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 300px;
            height: calc(100vh - #{$header-big + 40px});
        }

        &-bg {
            @include stretch;
            z-index: -1;
            overflow: hidden;

            img {
                object-fit: cover;
                position: absolute;
                height: calc(100% + #{100vh * $heroBgParallaxRatio});
                width: 100%;
                left: 0;
                bottom: 0;
                transform: translateY(calc(var(--screensScrolled) * 100vh * #{$heroBgParallaxRatio}));
            }

            &:after {
                @include pseudos;
                @include stretch;
                background: linear-gradient(
                    180deg,
                    rgba($color-raw-dark, 0) 35.44%,
                    rgba($color-raw-dark, 0.1) 62.44%,
                    rgba($color-raw-dark, 0) 100%
                );

                @include themeDark {
                    background: linear-gradient(
                        180deg,
                        rgba($color-raw-reverse-dark, 0) 35.44%,
                        rgba($color-raw-reverse-dark, 0.1) 62.44%,
                        rgba($color-raw-reverse-dark, 0) 100%
                    );
                }
            }
        }

        h1 {
            @extend %header-large;

            @include bp(lg) {
                text-align: center;
            }
        }
    }

    &--form {
        z-index: 2;
        position: relative;
        margin-top: -218px;

        @include bp(lg) {
            margin-top: -185px;
        }
    }
}
