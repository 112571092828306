.page_order {
    &--layout {
        .block_default--header {
            text-align: left;

            h1 {
                @extend %header-large;
            }
        }

        &-rate_info {
            position: relative;
            z-index: 1;
            padding-bottom: 40px;

            @include bp(lg){
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                & > *{
                    flex-grow: 1;
                    flex-shrink: 1;
                }
            }

            &-bg {
                z-index: -1;
                position: absolute;
                left: 50%;
                margin-left: -50vw;
                width: 100vw;
                height: auto;
                bottom: 0;
                top: 40px;
                @extend %bg-gradient;
                overflow: hidden;

                @include bp(lg) {
                    top: 50px;
                }

                img {
                    bottom: 0;
                    width: 100%;
                    left: 0;
                    position: absolute;
                }
            }

            &-col {
                display: block;
                position: relative;
                @extend %border-gradient;
                z-index: 2;
                border-radius: 10px;
                padding: 40px 20px;
                margin-bottom: 40px;

                @include bp(lg) {
                    margin-bottom: 0;
                    margin-right: 40px;
                    padding: 60px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @include bp(xl){
                    &:last-child{
                        width: 530px;
                        flex-grow: 0;
                        flex-shrink: 0;
                    }
                }    

                &:last-child {
                    margin-bottom: 0;
                }

                p,
                h3 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;

                    & > strong {
                        font-family: $font2;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 125%;
                        text-align: right;
                        letter-spacing: 0.01em;
                        white-space: nowrap;

                        & > *{
                            font-family: $font1;
                            font-size: 16px;
                            line-height: 20px;
                            letter-spacing: 0.01em;
                        }

                        small{
                            font-weight: 400;
                        }

                        span{
                            font-weight: 700;
                        }
                    }
                }

                h3 {
                    margin-bottom: 40px;
                    @extend %header-medium;

                    @include bp-to(md) {
                        flex-direction: column;
                        align-items: flex-start;

                        & > * {
                            &:last-child {
                                margin-top: 10px;
                            }
                        }
                    }

                    & > span{
                        small{
                            font-family: $font1;
                            display: block;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 125%;
                            letter-spacing: 0.01em;
                            opacity: 0.5;
                        }
                    }
                }

                p {
                    margin: 10px 0;

                    &:last-child{
                        margin-bottom: 0;
                    }

                    & > * {
                        &:first-child{
                            max-width: 50%;
                        }

                        &:last-child {
                            text-align: right;
                        }
                    }
                }

                footer{
                    margin-top: 20px;
                    padding-top: 30px;
                    border-top: 1px solid $color-gray;
                }
            }
        }

        &-rate_properties{
            margin-top: 80px;
            max-width: 1040px;

            fieldset{
                max-width: 666px;
            }

            .col-md-6{
                @include bp(lg){
                    &:first-child{
                        padding-right: 40px;
                    }

                    &:last-child{
                        padding-left: 40px;
                    }
                }
            }

            h3{
                @extend %header-medium;
                margin-top: 80px;
                margin-bottom: 40px;

                @include bp(lg){
                    margin-top: 140px;
                }
            }

            .btn{
                @include bp-to(md){
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}
