@mixin addClassProperty($classPrefix, $classPostfix) {
    @each $bp, $val in $breakpoints {
        $class: "#{$classPrefix}-";

        @if $bp != xs {
            $class: $class + "#{$bp}-";
        }

        $class: $class + $classPostfix;

        .#{$class} {
            @include bp($bp) {
                @content;
            }
        }
    }
}

@mixin addClassProperties($propertiesList) {
    @each $val in $propertiesList {
        @if length($val) == 2 {
            $prop: nth($val, 1);
            $val: nth($val, 2);

            @include addClassProperty($prop, $val) {
                #{$prop}: $val;
            }
        } @else {
            $pref: nth($val, 1);
            $postf: nth($val, 2);
            $prop: nth($val, 3);
            $val: nth($val, 4);

            @include addClassProperty($pref, $postf) {
                #{$prop}: $val;
            }
        }
    }
}
