.input_container {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 40px;
    font-size: 0;
    line-height: 0;
    pointer-events: none;
    @include clearfix;
    
    &.d-none {
        display: none;
    }

    &:last-child {
        margin-bottom: 0;
    }

    input,
    textarea,
    select {
        pointer-events: all;
    }

    &.low_margin{
        margin-bottom: 30px;
    }

    &.hight_margin{
        margin-bottom: 50px;
    }

    &--label {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        @extend %form-label;
        @include anim-f(transform, color, opacity);
        transform: translate3d(0, 0, 0) scale(1);
        transform-origin: 0 100%;
        pointer-events: none;
        opacity: 0.6;
        color: $color-dark;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: auto;
        max-width: 100%;
        right: 14px;
        overflow: hidden;

        .focused ~ &,
        select ~ & {
            transform: translate3d(0, calc(-100% - 2px), 0) scale(0.75);
            opacity: 1;
            white-space: normal;
            width: calc(133% - 14px);
            max-width: none;
        }
    }

    &--error {
        display: none;
        font-size: 12px;
        line-height: 1.1em;
        color: $color-red !important;
        position: absolute;
        top: 100%;
        margin-top: 2px;
        left: 0;
        margin-left: 0;

        input[type="checkbox"] ~ &,
        input[type="radio"] ~ & {
            margin-left: 34px;
        }

        &.validation-visible {
            display: block;
        }

        input:valid ~ &,
        textarea:valid ~ & {
            display: none;
        }
    }
}
