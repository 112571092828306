.form_default {
    &.fields_alt {
        #{$text-inputs} {
            border-width: 1px;
            border-color: rgba($color-raw-dark, 0.5);
            padding: 13px 9px;
            border-radius: 5px;

            & ~ .input_container--label,
            & ~ .input_container--error{
                margin-left: 14px;
            }

            @include themeDark {
                border-color: rgba($color-raw-reverse-dark, 0.5);
            }
        }

        textarea{
            height: 100px;
        }
    }

    #{$text-inputs} {
        background: transparent;
        border-radius: 0;
        border-style: solid;
        border-width: 0 0 1px;
        border-color: $color-gray;
        padding: 0 0 1px;
        height: auto;
        width: 100%;
        @extend %form-label;
        background-color: transparent;
        box-shadow: none;
        appearance: none;
        @include anim-f(border);

        &:focus,
        &:hover {
            border-color: $color-dark !important;
        }

        &.validation-touched:invalid {
            border-color: $color-red;
        }

        // &.validation-touched:valid{
        //     border-color: $color-green;
        // }

        &::placeholder {
            color: rgba($color-raw-dark, 0.5);

            @include themeDark {
                color: rgba($color-raw-reverse-dark, 0.5);
            }
        }
    }

    input[type="number"] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            visibility: hidden;
            appearance: none;
        }
    }

    textarea {
        resize: none;
        height: 25px;
    }
}
