.anim {
    &-item {
        .is-ie & {
            animation: none !important;
            visibility: visible !important;
        }

        animation-duration: 0.5s;
        animation-timing-function: ease-out;

        &:not(.animate__animated) {
            visibility: hidden;
            animation-play-state: paused;
        }
    }

    &-fadeInZoomOut {
        @keyframes fadeInZoomOut {
            0% {
                transform: scale(1.3);
                opacity: 0;
            }
            100% {
                opacity: 1;
                transform: scale(1);
            }
        }

        animation-name: fadeInZoomOut;
    }

    &-fadeInUp{
        @keyframes fadeInUpAlt {
            0%{
                transform: translateY(100px);
                opacity: 0;
            }
            100%{
                transform: translateY(0);
                opacity: 1;
            }
        }

        animation-name: fadeInUpAlt;
    }
}
