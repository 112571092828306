// i[class^="icon-"] {
//   display: inline-block;
//   vertical-align: middle;
// }


svg.icon{
    stroke-width: 0;
    display: inline-block;
    vertical-align: middle;
    height: 1em;
    line-height: 1em;
}

%icon-base{
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

@mixin arrow-left-gray{
    background-image: url("data:image/svg+xml,%3Csvg width='27' height='14' viewBox='0 0 27 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7L9.15686 1M1 7L9.15686 13M1 7H27' stroke='%23CDCDCD'/%3E%3C/svg%3E%0A");
    width: 27px;
    height: 14px;
}