%form-label {
    font-family: $font1;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 125%;
    letter-spacing: 0.01em;
}

.form_default {
    display: block;
    text-align: left;

    * {
        outline: none;
    }

    &.submitting {
    }

    &.submitted {
    }

    .g-recaptcha {
    }

    // fieldset {
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: wrap;
    //     align-items: flex-start;
    //     justify-content: flex-start;

    //     .input_container {
    //         width: auto;
    //         margin-bottom: 0 !important;
    //         margin-top: 12px;
    //         margin-right: 32px;

    //         &:last-child {
    //             margin-right: 0;
    //         }
    //     }

    //     legend {
    //         display: block;
    //         position: static;
    //         font-style: normal;
    //         font-weight: normal;
    //         font-size: 16px;
    //         line-height: 150%;
    //     }
    // }

    &--notice {
        margin-top: 10px !important;
        margin-bottom: 0 !important;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.01em;
        opacity: 0.5;
    }

    .row {
        margin-bottom: 0;

        & > * {
            margin-bottom: 40px;

            & > .input_container:last-child {
                margin-bottom: 0;
            }
        }
    }

    fieldset {
        padding: 0;
        margin-bottom: 46px;

        @include bp(md) {
            .input_container{
                padding-left: 40px;
            }
        }

        legend {
            display: block;
            margin-bottom: 23px;
            @extend %header-medium;
            width: 100%;

            @include bp(md) {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            & > strong {
                margin-top: 40px;
                text-align: right;
                font-weight: inherit;
                white-space: nowrap;

                @include bp(md) {
                    margin-top: 0;
                    margin-left: 20px;
                }

                small {
                    font-size: 16px;
                    line-height: 20px;
                    font-family: $font1;
                    font-weight: 400;
                }
            }
        }

        .input_container {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            & > .input_container--label {
                width: 100% !important;

            }
        }
    }
}
