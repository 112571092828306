.page_rates {
    &--block_rates {
        .block_default--header {
            text-align: left;
            margin: 0 0 20px;

            @include bp(lg) {
                margin: 0 0 40px;
            }

            .block_text {
                max-width: none;
                margin: 0;
                color: rgba($color-raw-dark, 0.7);

                @include themeDark {
                    color: rgba($color-raw-reverse-dark, 0.7);
                }
            }
        }

        .wrapper {
            z-index: 1;
        }

        &-decor {
            @include bp(xl) {
                top: 950px;
                font-size: 0;
                line-height: 0;
                right: -200px;
                z-index: -1;
                position: absolute;
            }
        }

        &-slider {
            @include bp(xl) {
                @include reset-slider;

                & > .swiper-container {
                    & > .swiper-wrapper {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-bottom: -40px;

                        & > .swiper-slide {
                            width: 370px;
                            margin-bottom: 40px;
                            opacity: 0;
                            visibility: visible;
                            top: 100px;
                            transition: opacity 0.3s ease-out, top 0.3s ease-out, transform 1s ease !important;

                            body:not(.is-ie) &:not(.anim-inViewport) {
                                visibility: hidden;
                            }

                            &.anim-inViewport {
                                top: 0;
                                opacity: 1;

                                @for $i from 0 to 5 {
                                    &.anim-order#{$i} {
                                        transition: opacity 0.3s ease-out #{$i * 0.1s}, top 0.3s ease-out #{$i * 0.1s},
                                            transform 1s ease !important;
                                    }
                                }
                            }

                            &:nth-child(3n) {
                                margin-right: 0;
                            }

                            &.page_rates--block_facility-slider-item {
                                transform: translateY(0) !important;

                                &:hover {
                                    transform: translateY(-10px) !important;
                                }
                            }
                        }
                    }
                }
            }

            .swiper-container {
                overflow: visible;
            }

            &-item {
                &_alt {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 125%;
                    letter-spacing: 0.01em;

                    h3 {
                        @extend %header-medium;
                        text-align: center;
                        padding-top: 35px;
                        margin-bottom: 40px;
                        color: $color-accent;
                    }

                    p:last-of-type{
                        margin-bottom: 20px;
                    }

                    .btn{
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }

                &,
                &_alt {
                    @extend %shadow-default;
                    background-color: $color-light;
                    overflow: hidden;
                    padding: 0 20px 30px;
                    height: auto;
                    margin-right: 30px;
                    width: 300px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: stretch;
                    animation: fadeInUpAlt 0.5s ease-out paused both;
                    visibility: hidden;

                    @include bp(lg) {
                        width: 370px;
                        margin-right: 25px;

                        form {
                            & > div {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: flex-start;

                                label {
                                    margin-bottom: 0 !important;
                                }
                            }
                        }
                    }

                    @for $i from 1 to 6 {
                        &.anim-order#{$i} {
                            animation-delay: #{0.1s * $i};
                        }
                    }

                    body.is-ie & {
                        animation: none;
                        visibility: visible;
                    }

                    &.anim-inViewport {
                        animation-play-state: running;
                        visibility: visible;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    footer,
                    footer > form {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: stretch;
                    }

                    & > .btn {
                        margin-top: auto;
                    }
                }

                h3 {
                    @extend %header-medium;
                    color: $color-light;
                    padding: 35px 0 20px;
                    margin-bottom: 30px;
                    position: relative;
                    z-index: 1;

                    &:before {
                        @include pseudos;
                        width: auto;
                        height: 100%;
                        top: 0;
                        left: -20px;
                        right: -20px;
                        background: linear-gradient(26.82deg, $color-accent 12.73%, $color-accent-alt 92.73%);
                        position: absolute;
                        z-index: -1;
                    }

                    & > span {
                        position: absolute;
                        font-family: $font1;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 125%;
                        letter-spacing: 0.01em;
                        color: $color-accent;
                        padding: 4px 15px;
                        background-color: $color-light;
                        right: -20px;
                        top: 0;
                        border-radius: 0 0 0 5px;
                    }

                    & + p {
                        margin-top: 0;
                    }
                }

                header {
                    padding-bottom: 40px;
                    border-bottom: 1px solid $color-gray;
                    margin-bottom: 10px;
                }

                i {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    vertical-align: top;
                    margin-left: 2px;
                    color: $color-dark;
                    opacity: 0.5;
                    @include anim-f(opacity);
                    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.375 8.25V5.25H4.875V6H5.625V8.25H4.5V9H7.5V8.25H6.375Z' fill='%231E1F20' /%3E%3Cpath d='M6 3C5.88875 3 5.78 3.03299 5.68749 3.0948C5.59499 3.15661 5.52289 3.24446 5.48032 3.34724C5.43774 3.45002 5.4266 3.56312 5.44831 3.67224C5.47001 3.78135 5.52359 3.88158 5.60225 3.96025C5.68092 4.03891 5.78115 4.09249 5.89026 4.11419C5.99938 4.1359 6.11248 4.12476 6.21526 4.08218C6.31804 4.03961 6.40589 3.96751 6.4677 3.87501C6.52951 3.78251 6.5625 3.67375 6.5625 3.5625C6.5625 3.41332 6.50324 3.27024 6.39775 3.16475C6.29226 3.05926 6.14919 3 6 3Z' fill='%231E1F20' /%3E%3Cpath d='M6 11.25C4.96165 11.25 3.94662 10.9421 3.08326 10.3652C2.2199 9.78834 1.54699 8.9684 1.14963 8.00909C0.752275 7.04978 0.648307 5.99418 0.85088 4.97578C1.05345 3.95738 1.55347 3.02192 2.28769 2.28769C3.02192 1.55347 3.95738 1.05345 4.97578 0.85088C5.99418 0.648307 7.04978 0.752275 8.00909 1.14963C8.9684 1.54699 9.78834 2.2199 10.3652 3.08326C10.9421 3.94662 11.25 4.96165 11.25 6C11.25 7.39239 10.6969 8.72775 9.71231 9.71231C8.72775 10.6969 7.39239 11.25 6 11.25ZM6 1.5C5.10999 1.5 4.23996 1.76392 3.49994 2.25839C2.75992 2.75286 2.18314 3.45566 1.84254 4.27793C1.50195 5.10019 1.41284 6.00499 1.58647 6.87791C1.7601 7.75082 2.18869 8.55265 2.81802 9.18198C3.44736 9.81132 4.24918 10.2399 5.1221 10.4135C5.99501 10.5872 6.89981 10.4981 7.72208 10.1575C8.54434 9.81686 9.24715 9.24009 9.74162 8.50007C10.2361 7.76005 10.5 6.89002 10.5 6C10.5 4.80653 10.0259 3.66194 9.18198 2.81802C8.33807 1.97411 7.19348 1.5 6 1.5Z' fill='%231E1F20' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    position: relative;
                    z-index: 3;

                    &:hover,
                    &:focus {
                        opacity: 1;

                        span {
                            transition-delay: 0s;
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                    span {
                        position: absolute;
                        display: block;
                        width: auto;
                        max-width: 260px;
                        right: 0;
                        bottom: 100%;
                        margin-bottom: 6px;
                        background: linear-gradient(
                                26.82deg,
                                rgba(255, 115, 0, 0.1) 12.73%,
                                rgba(255, 235, 0, 0.1) 92.73%
                            ),
                            linear-gradient(0deg, rgba(255, 115, 0, 0.05), rgba(255, 115, 0, 0.05)), #ffffff;
                        border-radius: 5px 5px 0 5px;
                        white-space: nowrap;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 10px;
                        line-height: 125%;
                        font-family: $font1;
                        text-align: right;
                        letter-spacing: 0.01em;
                        padding: 6px 10px;
                        visibility: hidden;
                        opacity: 0;
                        transition: opacity 0.3s ease, visibility 0.001s linear 0.3s;

                        &:after {
                            @include pseudos;
                            width: 100%;
                            height: 6px;
                            top: 100%;
                            left: 0;
                            position: absolute;
                        }

                        @include themeDark {
                            background: linear-gradient(
                                    26.82deg,
                                    rgba(255, 115, 0, 0.1) 12.73%,
                                    rgba(255, 235, 0, 0.1) 92.73%
                                ),
                                linear-gradient(0deg, rgba(255, 115, 0, 0.05), rgba(255, 115, 0, 0.05)), #000000;
                        }

                        @include bp(lg) {
                            font-size: 12px;
                            max-width: 330px;
                        }
                    }
                }

                p {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin: 10px 0;

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    & > span {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 125%;
                        letter-spacing: 0.01em;
                        color: $color-dark;

                        @include bp-to(lg) {
                            max-width: 140px;
                        }
                    }

                    & > strong {
                        font-family: $font2;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 125%;
                        letter-spacing: 0.01em;
                        white-space: nowrap;

                        small {
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 700;
                            font-family: $font1;
                        }
                    }

                    & > svg {
                        margin-right: 20px;
                        font-size: 30px;
                        fill: $color-dark;
                        opacity: 0.7;
                        flex-grow: 0;
                        flex-shrink: 0;
                    }

                    & > small {
                        opacity: 0.7;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 125%;
                        letter-spacing: 0.01em;
                    }

                    & > a {
                        display: inline-block;
                        text-decoration: underline;
                        color: $color-dark;
                        font-weight: 300;
                        opacity: 0.7;
                        @include anim-f(opacity);

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                h4 {
                    margin-bottom: 20px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 125%;
                    letter-spacing: 0.01em;
                }

                footer {
                    margin-top: auto;
                    padding-top: 30px;
                    flex-grow: 0;

                    & > p {
                        &:first-child {
                            margin-top: auto;
                        }
                    }

                    p {
                        margin: 10px 0;

                        &:last-of-type {
                            margin-bottom: 15px;
                        }
                    }
                }

                label {
                    display: block;
                    width: 100%;
                    position: relative;
                    margin-bottom: 20px;

                    @include bp(lg) {
                        width: 135px;
                        margin-bottom: 10px;
                    }

                    &:last-of-type {
                        margin-bottom: 10px;
                    }

                    input[type="radio"] {
                        @include hideField;

                        &:checked + span {
                            & > strong {
                                color: $color-accent;

                                &:before {
                                    border-color: $color-accent;
                                }

                                &:after {
                                    opacity: 1;
                                }
                            }
                        }

                        & + span {
                            display: block;
                            cursor: pointer;

                            &:hover {
                                & > strong {
                                    color: $color-accent;

                                    &:before {
                                        border-color: $color-accent;
                                    }
                                }
                            }

                            & > strong {
                                position: relative;
                                padding-left: 25px;
                                font-family: $font2;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 24px;
                                line-height: 125%;
                                letter-spacing: 0.01em;
                                display: block;
                                color: $color-dark;
                                @include anim-f(color);
                                margin-bottom: 3px;

                                small {
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 20px;
                                    font-family: $font1;
                                    letter-spacing: 0.01em;
                                }

                                &:before {
                                    @include pseudos;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 2px;
                                    border: 1px solid $color-dark;
                                    @include anim-f(border);
                                    position: absolute;
                                    left: 0;
                                    top: 6px;
                                }

                                &:after {
                                    @include pseudos;
                                    width: 11px;
                                    height: 6px;
                                    border-style: solid;
                                    border-width: 0 0 2px 2px;
                                    border-color: $color-accent;
                                    transform-origin: 50% 50%;
                                    transform: translateZ(0) rotate(-45deg);
                                    position: absolute;
                                    left: 4px;
                                    top: 11px;
                                    opacity: 0;
                                    @include anim-f(opacity);
                                }
                            }

                            & > span {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 125%;
                                letter-spacing: 0.01em;
                                color: $color-dark;
                                display: block;
                            }

                            & > small {
                                margin-top: 10px;
                                display: block;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 16px;
                                line-height: 125%;
                                letter-spacing: 0.01em;
                                color: $color-accent;
                            }
                        }
                    }
                }

                .accordion{
                    display: block !important;
                    margin: 10px 0 15px;
                    padding-bottom: 22px;
                    position: relative;

                    &-header{
                        display: inline-block;
                        text-decoration: underline;
                        color: $color-dark;
                        font-weight: 300;
                        opacity: 0.7;
                        @include anim-f(opacity);
                        position: absolute;
                        bottom: 0;
                        left: 0;

                        &:hover {
                            opacity: 1;
                        }

                        & > span{
                            &:first-child{
                                display: inline;
                            }

                            &:last-child{
                                display: none;
                            }
                        }
                    }

                    &.expanded{
                        .accordion-header{
                            & > span{
                                &:first-child{
                                    display: none;
                                }

                                &:last-child{
                                    display: inline;
                                }
                            }
                        }
                    }

                    &-content{
                        p{
                            display: block !important;
                            margin: 0 !important;
                        }
                    }

                    &-container{
                        padding-bottom: 7px;
                    }
                }
            }
        }

        .tabs + .tabs {
            margin-top: 40px;

            @include bp(lg) {
                margin-top: 60px;
            }
        }
    }

    &--block_features {
        .block_default--header {
            @include bp(md) {
                text-align: left;

                & > * {
                    max-width: none;
                }
            }

            @include bp(lg) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 60px;

                h2 {
                    width: 40.8%;
                    flex-grow: 0;
                    flex-shrink: 0;
                    font-size: 24px;
                    line-height: 125%;
                    padding-right: 50px;
                }

                .block_text {
                    flex-grow: 1;
                    flex-shrink: 1;
                    margin-left: 20px;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            .block_text {
                color: $color-dark;
            }
        }

        &-alt {
            .block_default--header {
                @include bp(lg) {
                    h2 {
                        font-weight: bold;
                        font-size: 36px;
                        line-height: 125%;
                        letter-spacing: 0.01em;
                    }
                }
            }
        }

        &-img {
            display: block;
            position: relative;
            font-size: 0;
            line-height: 0;
            margin-bottom: 80px;

            @include bp(lg) {
                margin-bottom: 140px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &-list {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 125%;
            letter-spacing: 0.01em;
            margin-bottom: -40px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;

            li {
                position: relative;
                padding-left: 60px;
                margin-bottom: 40px;
                width: 100%;

                @include bp(md, lg) {
                    width: calc(50% - 20px);
                    margin-right: 40px;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }

                @include bp(lg, xl) {
                    width: calc(33.3333% - 26.666px);
                    margin-right: 40px;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }

                @include bp(xl) {
                    width: 200px;
                    margin-right: 40px;
                    padding-left: 0;
                    padding-top: 70px;

                    &:nth-child(5n) {
                        margin-right: 0;
                    }

                    svg {
                        width: 50px;
                        height: 50px;
                        left: 0;
                        top: 0;
                    }
                }

                svg {
                    width: 40px;
                    height: 40px;
                    fill: $color-accent;
                    top: 0;
                    left: 0;
                    position: absolute;
                }
            }
        }
    }

    &--block_form {
        overflow: visible !important;
    }

    &--block_order {
        padding-bottom: 160px;
        margin-bottom: 0;

        @include bp(lg) {
            padding-bottom: 140px;
            margin-bottom: 0;
        }

        & + .block_default {
            margin-top: 0 !important;
        }

        .wrapper {
            z-index: 1;
            height: 500px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: stretch;
        }

        &-bg {
            position: absolute;
            width: 100vw;
            left: 50%;
            margin-left: -50vw;
            height: 500px;
            z-index: -1;
            top: 0;
            overflow: hidden;

            @include bp(md, lg) {
                height: 400px;
            }

            img {
                position: absolute;
                left: 0;
                // top: -20%;
                top: 0;
                width: 100%;
                height: 100%;
                // height: 140%;
                object-fit: cover;
                object-position: 50% calc(50% - 2% * var(--screensTotal));
                // transform: translateZ(0) translateY(calc(var(--pageProgress) * 20%));
            }
        }

        &-text {
            @extend %bg-form;
            @extend %shadow-default;
            padding: 40px 20px;
            margin-bottom: -80px;

            @include bp(md) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            @include bp(lg) {
                padding: 60px;
                margin-bottom: -40px;
            }

            @include bp(xl) {
                padding: 60px 130px;
            }

            .btn {
                @include bp-to(md) {
                    width: 100%;
                    display: block;
                }

                @include bp(md) {
                    flex-grow: 0;
                    flex-shrink: 0;
                }
            }

            h2 {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 125%;
                letter-spacing: 0.01em;
                margin-bottom: 20px;

                @include bp(md) {
                    margin-bottom: 0;
                    margin-right: 40px;
                    flex-shrink: 1;
                    flex-grow: 1;
                }

                @include bp(lg) {
                    font-family: $font2;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 125%;
                    letter-spacing: 0.01em;
                }
            }
        }
    }

    &--block_connect {
        &-steps {
            counter-reset: stepsCnt;

            &-item {
                counter-increment: stepsCnt;
                margin-bottom: 60px;
                animation: fadeIn 0.5s ease-out both paused;

                @include bp(lg) {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    padding-bottom: 60px;
                    border-bottom: 1px solid $color-gray;

                    &:last-child {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                }

                @for $i from 1 to 5 {
                    &.anim-order#{$i} {
                        animation-delay: #{0.1 * $i};
                    }
                }

                &.anim-inViewport {
                    animation-play-state: running;
                }

                body.is-ie & {
                    animation: none;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                header {
                    margin-bottom: 40px;

                    @include bp(lg) {
                        margin-bottom: 0;
                        width: 300px;
                        flex-grow: 0;
                        flex-shrink: 0;
                        margin-right: 0;
                    }

                    @include bp(xl) {
                        width: 375px;
                    }

                    & > small {
                        display: block;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 125%;
                        letter-spacing: 0.01em;
                        opacity: 0.5;
                        margin-bottom: 10px;

                        &:after {
                            content: " " counter(stepsCnt);
                        }
                    }

                    & > h3 {
                        @extend %header-block;
                    }
                }

                & > span {
                    display: block;
                    position: relative;

                    @include bp-to(lg) {
                        @include aspect-ratio(320, 197);
                        width: 100%;
                        margin-bottom: 40px;
                    }

                    @include bp(lg) {
                        width: 275px;
                        height: 170px;
                        margin: 0 20px;
                        flex-grow: 0;
                        flex-shrink: 0;
                    }

                    img {
                        @include stretch;
                        object-fit: cover;
                    }
                }

                .block_text {
                    @include bp(lg) {
                        max-width: 393px;
                        width: auto;
                        margin-left: auto;
                    }

                    ul,
                    ol {
                        li {
                            margin-bottom: 5px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &--block_facility {
        .block_default--header {
            text-align: left;

            .block_text {
                max-width: none;
                margin: 0;
                color: $color-dark;
            }

            .row {
                & > * {
                    @include bp(xl) {
                        &:first-of-type {
                            padding-right: 50px;
                        }

                        &:last-of-type {
                            padding-left: 50px;
                        }
                    }
                }
            }
        }

        &-slider {
            @extend %navigation-alt;

            @include bp(lg) {
                margin-top: 140px;
            }

            .swiper-pagination,
            .swiper-button-prev,
            .swiper-button-next {
                @include bp-to(lg) {
                    display: none;
                }
            }

            .swiper-container {
                overflow: visible;
            }

            &-item {
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                background-color: $color-light;
                @extend %shadow-default;
                width: 300px;
                padding: 0 20px 30px;
                margin-right: 30px;
                overflow: hidden;

                @include bp(lg) {
                    margin-right: 25px;
                    width: 370px;
                    @include anim-s(transform);

                    &:hover {
                        transform: translateY(-10px);

                        & > span:before {
                            opacity: 1;
                        }
                    }
                }

                @include bp-to(lg) {
                    &.swiper-slide-active {
                        & > span:before {
                            opacity: 1;
                        }
                    }
                }

                @include bp(xl) {
                    visibility: hidden;
                    opacity: 0;
                    transition: transform 1s ease, visibility 0.001s linear 0.3s, opacity 0.3s ease !important;

                    &.swiper-slide-active,
                    &.swiper-slide-next,
                    .swiper-slide-next + & {
                        opacity: 1;
                        visibility: visible;
                        transition-delay: 0s !important;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                & > span {
                    display: block;
                    position: relative;
                    width: auto;
                    margin: 0 -20px 20px;
                    height: 204px;
                    z-index: 1;
                    background-color: $color-accent;

                    &:before {
                        @include pseudos;
                        @include stretch;
                        z-index: 1;
                        background: radial-gradient(
                                circle at 70% 180%,
                                #{rgba($color-raw-accent-alt, 0.2)},
                                #{rgba($color-raw-accent-alt, 0.2)} 65%,
                                transparent 65.01%
                            ),
                            radial-gradient(
                                circle at 20% 170%,
                                #{rgba($color-raw-accent-alt, 0.2)},
                                #{rgba($color-raw-accent-alt, 0.2)} 65%,
                                transparent 65.01%
                            );
                        opacity: 0;
                        @include anim-m(opacity);
                    }

                    img {
                        max-width: 90%;
                        max-height: 90%;
                        @include centre;
                        z-index: 2;
                    }
                }

                h3 {
                    @extend %header-medium;
                    text-align: center;
                    margin-bottom: 30px;
                }

                .block_text {
                    font-size: 16px;

                    p,
                    ul,
                    ol {
                        margin: 10px 0;
                    }

                    ul,
                    ol {
                        padding-left: 20px;

                        @include bp-to(lg) {
                            padding-left: 0;
                        }
                    }

                    ul > li {
                        margin-bottom: 7px;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }

                & > p {
                    margin: 5px 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    &:first-of-type {
                        margin-bottom: 25px;
                        margin-top: 10px;
                    }

                    &:last-of-type {
                        margin-bottom: 30px;
                    }

                    & > a {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 125%;
                        letter-spacing: 0.01em;
                        text-decoration-line: underline;
                        opacity: 0.5;
                        @include anim-f(opacity);

                        &:hover {
                            opacity: 1;
                        }
                    }

                    & > span {
                        small {
                            display: block;
                            font-family: $font2;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 11px;
                            line-height: 150%;
                            opacity: 0.5;
                        }
                    }

                    & > strong {
                        font-family: $font2;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 125%;
                        text-align: right;
                        letter-spacing: 0.01em;
                        white-space: nowrap;
                        margin-left: 20px;

                        small {
                            font-family: $font1;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 20px;
                            letter-spacing: 0.01em;
                        }
                    }
                }

                & > .button {
                    margin-top: auto;
                }
            }
        }
    }
}
